.servicepage-bg-white {
    background-color: #ffffff;
}
.service-page-header {
    background: #f8f8fb url(../../../img/08.webp);
    display: block;
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    background-repeat: repeat !important;
    background-size: auto !important;
    text-align: center;
    
    svg {
        #followPath {
            fill: none;
            stroke: #86bc24;
            stroke-width: 8;
            stroke-dasharray: 2870px;
            stroke-dashoffset: 2870px;
            -webkit-animation: drawPath 6s linear infinite;
            animation: drawPath 6s linear infinite;
        }
         #dashedPath {
            fill: none;
            stroke: #fff;
            stroke-width: 14;
            stroke-dasharray: 50px;
        } 
        #airplain {
            fill: #86bc24;                       
            -webkit-transform: translate(-2px,-45px);
            transform: translate(-2px, -45px);
            -moz-transform:  translate(-15rem, -45px);
        }
    }
    .ani_right {
        height: 320px;
        width: 320px;
        right: 0;
        top: 0;
        position:absolute;
    }
    @-webkit-keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }
        @keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }

        .ani.ani-5 {
            left: 5%;
            top: 10%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            position:absolute;
       
        .moving-square-frame {
            position: absolute;
            height: 40px;
            width: 40px;
            top: 10px;
            left: 10px;
            opacity: .4;
            border: 1px solid #1d1d1f;
        }
        .ani-moving-square {
            border: 1px solid #86bc24;
            height: 40px;
            width: 40px;
            opacity:1;
            -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
            animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        }
        @-webkit-keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
            @keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
    }


.ani_inner_banner{
    left: 100px;
    bottom: 100px;
    -webkit-transform: translate(-50%, 50%) scale(0.8);
    transform: translate(-50%, 50%) scale(0.8);
    position: absolute;

    .ani-cube {
        -webkit-perspective: 20000px;
        perspective: 20000px;
        height: 100px;
        width: 100px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        -webkit-animation: ani-cube-1 20s linear infinite;
        animation: ani-cube-1 20s linear infinite;
    }

    .ani-cube.ani-cube-3 {
        -webkit-transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  

        .cube-face.face_back {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, -50px);
            transform: translate3d(0px, 0px, -50px);
            z-index: 1;
        }
        .cube-face {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 100px;
            height: 100px;
        }  

       
       
        .cube-face.face_front {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, 50px);
            transform: translate3d(0px, 0px, 50px);
            z-index: 10;
        }
        .cube-face.face_right {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 8;
        }
        .cube-face.face_left {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 9;
        }
        .cube-face.face_top {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 11;
        }
        .cube-face.face_bottom {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 5;
        }
        
        .cube-face.face_front {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_right {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_left {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_top {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_bottom {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_back {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }             

        @-webkit-keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            @keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            
    }

}        





}
@media (max-width: 992px) {
    .service-page-header {
        padding: 160px 0;
    }
}
@media (max-width: 576px) {
    .service-page-header {
        padding: 160px 0 110px;
    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}
.servicepage-title-pattern-img {
    vertical-align: middle;
}
.servicepage-title-pattern {
    bottom: 0;
    position: absolute;
}

.servcepage-section-1 {
    background: #ffffff url(../../../img/14.png);
    background-size: cover;
    padding: 80px 0px;
    border-top: 1px solid #86bc24;
    position: relative;
    .about-bg{
        top:25%;  
      }    
}
.servicepage-section1-card {
    padding: 60px 30px;
    background: #fff;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out 0s;
    text-align: center;
    img{
        width: 80px;

    }
    h5 {
        margin: 30px 0 20px;
        text-transform: capitalize;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 26px;
        color: #1c2045;
    }
}









.fancy_service {
	border-radius: 5px;
	border: 1px solid #f4f8ff;
	box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
	margin-bottom: 60px;
	padding: 0;
	display: block;
	float: left;
	width: 100%;
	.fancy-info {
		float: left;
		width: 70%;
		padding: 30px;
      h4{
        text-transform: capitalize;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 30px;
        color: #1c2045;
      }
      p{
        position: relative;
        font-size: 15px;
        text-align: left;
        padding-left:0px;
        letter-spacing: 1px;
        color: #111121;
        margin:25px 0px;
        ul{
            padding-left:20px;
            
         li{
            color: #111121;
            list-style: circle;             
         }   
        }
      }  
      a{
          color:#86bc24;
      }
	}
	.fancy_img {
        position: absolute;
        right: -30px;
        bottom: -30px;
        width: 250px;
        float: left;
        z-index: -1;
        background: #f5f5f7;
        border-radius: 200px 0px 0px 0px;
        height: 250px;
        border: 1px solid #86bc24;
        display: flex;
        align-items: center;
        justify-content: center!important;
   img{
       width:100px;
       height: 100px;
       margin-top:15px;
       margin-left:15px;
   }

	}
	.fancy_block {
		display: block;
		float: left;
		width: 100%;
		position: relative;
		overflow: hidden;
		z-index: 1;
		background: #ffffff;
        min-height: 370px;
	}
	.bg_img {
		opacity: 0;
		position: absolute;
		right: -10px;
		top: -50px;
		-webkit-transition: all 0.5s ease-in-out;
		-webkit-animation: bounces 2s infinite alternate;
		transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		-webkit-transition: all 0.5s ease-in-out;
	}
	&:hover {
		.bg_img {
			opacity: 1;
		}
	}
   
}









@media (max-width: 1200px) {
    .servicepage-section1-card h5 {
        font-size: 18px;
        line-height: 24px;
    }
}
@media (max-width: 992px) {
    .servicepage-section1-card h5 {
        font-size: 18px;
        line-height: 28px;
    }
}
.servicepage-section1-card:hover {
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
}
.servicepage-section1-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    padding: 0;
}
.servicepage-section1-list li {
    position: relative;
    font-size: 15px;
    text-align: left;
    padding-left: 40px;
    letter-spacing: 1px;
    color: #111121;
}
.servicepage-section1-list li i {
    position: absolute;

    left: 0;
    top: -5px;
    color: $primary-color;
    font-size: 30px;
    line-height: 30px;
}
.servicepage-title::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 07s infinite linear;
    -webkit-animation: rotateline 07s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@keyframes infinite {
    0% {
        background: rgba(255, 120, 16, 1);
    }
    25% {
        background: rgba(255, 21, 106, 1);
    }
    50% {
        background: rgba(255, 94, 44, 1);
    }
    75% {
        background: rgba(187, 13, 76, 1);
    }
    100% {
        background: rgba(69, 204, 137, 1);
    }
}
.breadcrumb {
    padding: 0;
    background: 0 0;
    display: inline-block;
    border-radius: 0;
    margin: 0;
    text-transform: uppercase;
    color: #111121;
    flex-wrap: wrap;
    list-style: none;
    font-weight: 700;
    font-size: 15px;
}
.breadcrumb-item {
    display: inline-block;
    a {
        color: #111121 !important;
        font-weight: 700;
        font-size: 15px;
        text-decoration: none !important;
    }
}
.breadcrumb-item-active {
    display: inline-block;
    padding-left: 0.5rem;
    font-weight: 700;
    color: $primary-color;
    font-size: 15px;
}
.breadcrumb-item-active:before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}
.servicepage-title {
    font-size: 50px;
    color: #1c2045;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 2px;
    position: relative;
    padding-bottom: 20px;
}
@media (max-width: 767px) {
    .servicepage-title {
        font-size: 30px;
        line-height: 30px;
    }
}
@media (max-width: 576px) {
    .servicepage-title {
        font-size: 24px;
    }
}
.breadcrumb-item a:hover {
    color: #3414bc;
}
.servcepage-section-2 {
    background-color: #f3f6ff;
    padding: 80px 0px;
    position: relative;
}
.servicepage-section2-card {
    background: #fff;
    // padding: 50px 30px 50px 200px;
    padding: 60px 30px;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    text-align: center;
    height: 500px;
    h5 {
        margin: 30px 0 20px !important;
        text-transform: capitalize;
        margin-top: 0px;
        letter-spacing: 2px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color: #1c2045;
    }
    img {
        width: 80px;
    }
}

@media (max-width: 1200px) {
    .servicepage-section2-card h5 {
        font-size: 18px;
        line-height: 24px;
    }
}
@media (max-width: 992px) {
    .servicepage-section2-card h5 {
        font-size: 18px;
        line-height: 28px;
    }
}
@media (max-width: 576px) {
    .servicepage-section2-card {
        padding: 50px 20px;

        .servicepage-section2-img {
            margin-bottom: 20px;
            left: inherit;
            position: inherit;
            top: inherit;
            transform: inherit;
        }
    }
  
}
.servicepage-section2-card:hover {
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
}
.servicepage-section2-img {
    position: absolute;
    left: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transition: all 0.5s ease-in-out 0s;
    img{
        width:80px;
    }
}
.servicepage-section2-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    padding: 0;
}
.servicepage-section2-list li {
    position: relative;
    font-size: 15px;
    text-align: left;
    padding-left: 40px;
    letter-spacing: 1px;
    color: #111121;
}
.servicepage-section2-list li i {
    position: absolute;

    left: 0;
    top: -5px;
    color: $primary-color;
    font-size: 30px;
    line-height: 30px;
}



@media (max-width: 576px) {   
    .service-page-header {
        .ani_inner_banner {
            left: 10px;
            bottom: 10px;
            transform: translate(-20%, 20%) scale(0.5);
        } 
    }
    .service-page-header {
        .ani.ani-5{display:none;}
    }
    .service-page-header {
        .ani_right{
            height: 100px;
            width: 100px;
        }
    }
    .fancy_service{
        margin-bottom:15px;  
      }   

    .fancy_block{
        display:flex !important;
        flex-wrap: wrap;

        .fancy-info{
           width:100% !important;
           
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
          
        }
           
     .fancy_img{
        position: relative;
        left: -1rem;
        width: 60%;
        z-index: -1;
        height: 170px;
        justify-content: center !important;
        order: 1;
        top: -1rem;
        border-radius: 0px 0px 150px 0px;
 img{
    margin:0px;
    height:70px; 
 }
}
.fancy-info{
    padding:15px;
}
}
.servcepage-section-1{
 padding:15px 0px;   
}
.fancy_service{
.bg_img{
right: 0 !important;
}    
}
    
}