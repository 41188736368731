.tac-bg-white {
    background-color: #ffffff;
}
.terms-content {
    padding: 80px 0;
    background-color: #ffffff;
    border-top: 1px solid #86bc24;
    .terms-title {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 0px;
        letter-spacing: 2px;
    }
    .terms-title::before {
        position: absolute;
        left: 0;
        width: 100px;
        margin: 0 auto;
        bottom: 0;
        height: 3px;
        animation: rotateline 7s infinite linear;
        -webkit-animation: rotateline 7s infinite linear;
        content: "";
        border-radius: 5px;
        background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
    }
    .terms-subtext {
        line-height: 1.9;
        letter-spacing: 1px;
        color: #4f4e4e;
        font-size: 15px;
        margin-bottom: 1rem;
        font-weight: normal;
    }
    .terms-text {
        color: $primary-color;
        font-size: 24px;
        font-style: normal;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 30px;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .terms-list {
        position: relative;
        padding-left: 40px;
        color: #4f4e4e;
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 1.7;
        font-weight: normal;
        font-style: normal;
        i{
            position: absolute;
            left: 0;
            top: -5px;
            color: $primary-color;
            font-size: 30px;
            line-height: 30px;
        }
    }
}

@media screen and(max-width:1200px) {
    .privacy-content {
        .erms-title {
            font-size: 32px;
            line-height: 40px;
        }
    }
}
@media screen and(max-width:992px) {
    .privacy-content {
        .erms-title {
            font-size: 30px;
            line-height: 40px;
        }
    }
}
@media screen and(max-width:576px) {
    .privacy-content {
        .erms-title {
            font-size: 26px;
            line-height: 34px;
        }
    }
}
