.comman-page-header {
    background: #f8f8fb url(../../../img/08.webp);
    display: block;
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    text-align: center; 
    .page-title {
        font-size: 50px;
        color: #1c2045;
        line-height: 60px;
        font-weight: 700;
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 20px;
    }
    .page-title::before {
        left: 50%;
        -webkit-transform: translateX(-50%);
        position: absolute;
        width: 100px;
        margin: 0 auto;
        bottom: 0;
        height: 3px;
        animation: rotateline 07s infinite linear;
        -webkit-animation: rotateline 07s infinite linear;
        content: "";
        border-radius: 5px;
        background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
    }
    .page-title-pattern-img {
        vertical-align: middle;
    }
    .page-title-pattern {
        bottom: 0;
        position: absolute;
    }

    svg {
        #followPath {
            fill: none;
            stroke: #86bc24;
            stroke-width: 8;
            stroke-dasharray: 2870px;
            stroke-dashoffset: 2870px;
            -webkit-animation: drawPath 6s linear infinite;
            animation: drawPath 6s linear infinite;
        }
         #dashedPath {
            fill: none;
            stroke: #fff;
            stroke-width: 14;
            stroke-dasharray: 50px;
        } 
        #airplain {
            fill: #86bc24;                       
            -webkit-transform: translate(-2px,-45px);
            transform: translate(-2px, -45px);
            -moz-transform:  translate(-15rem, -45px);
        }
    }
    .ani_right {
        height: 320px;
        width: 320px;
        right: 0;
        top: 0;
        position:absolute;
    }
    @-webkit-keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }
        @keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }

        .ani.ani-5 {
            left: 5%;
            top: 10%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            position:absolute;
       
        .moving-square-frame {
            position: absolute;
            height: 40px;
            width: 40px;
            top: 10px;
            left: 10px;
            opacity: .4;
            border: 1px solid #1d1d1f;
        }
        .ani-moving-square {
            border: 1px solid #86bc24;
            height: 40px;
            width: 40px;
            opacity:1;
            -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
            animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        }
        @-webkit-keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
            @keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
    }


.ani_inner_banner{
    left: 100px;
    bottom: 100px;
    -webkit-transform: translate(-50%, 50%) scale(0.8);
    transform: translate(-50%, 50%) scale(0.8);
    position: absolute;

    .ani-cube {
        -webkit-perspective: 20000px;
        perspective: 20000px;
        height: 100px;
        width: 100px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        -webkit-animation: ani-cube-1 20s linear infinite;
        animation: ani-cube-1 20s linear infinite;
    }

    .ani-cube.ani-cube-3 {
        -webkit-transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  

        .cube-face.face_back {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, -50px);
            transform: translate3d(0px, 0px, -50px);
            z-index: 1;
        }
        .cube-face {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 100px;
            height: 100px;
        }  

       
       
        .cube-face.face_front {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, 50px);
            transform: translate3d(0px, 0px, 50px);
            z-index: 10;
        }
        .cube-face.face_right {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 8;
        }
        .cube-face.face_left {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 9;
        }
        .cube-face.face_top {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 11;
        }
        .cube-face.face_bottom {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 5;
        }
        
        .cube-face.face_front {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_right {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_left {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_top {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_bottom {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_back {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }             

        @-webkit-keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            @keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            
    }

}    

    
   
}
@media (max-width: 992px) {
    .comman-page-header {
        padding: 160px 0px;
    }
}
@media (max-width: 767px) {
    .comman-page-header {
        .page-title {
            font-size: 30px;
            line-height: 30px;
        }
    }
}
@media (max-width: 576px) {
    .comman-page-header {
        padding: 160px 0 110px;
        .page-title {
            font-size: 24px;
        }
    }
    .comman-page-header {
        .ani_inner_banner {
            left: 10px;
            bottom: 10px;
            transform: translate(-20%, 20%) scale(0.5);
        } 
        .ani_right {
            height: 100px;
            width: 100px;
        }
        .ani.ani-5{
            display:none;
        }

    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}





















