.navbar {
  padding: $navbar-padding-x;
  width: $full-width;
  z-index: 1050;
  

  .photo {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;
    img {
      width: $full-width;
    }
  }

  .navbar-nav {
    .nav-item .nav-link {
      position: relative;
      // color: inherit;
      line-height: 20px;
      display: inline-flex;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none !important;
      &:hover {
        color: #1D1D1F;
        &::before{
          position: absolute;
          left: 0;
          width: 100%;
          margin: 0 auto;
          bottom: 0;
          opacity: 1;
          height: 3px;
          -webkit-animation: rotateline 7s infinite linear;
          content: "";
          border-radius: 5px;
          animation: rotateline 7s infinite linear;
          background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
        }
      }
    }
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
  }

  .navbar-text {
    color: $white;
  }

  .btn {
    margin: 0 5px 0 10px;
  }
  p {
    margin: 0;
  }

  &.navbar-absolute {
    position: absolute;
    z-index: 1050;
  }

  &.navbar-transparent {
    background: $transparent-bg !important;
    padding-top: 25px;
  }

  &.bg-white {
    .navbar-nav {
      .search-bar.input-group {
        i {
          color: $black;
        }
      }

      .search-bar.input-group .form-control {
        background: rgba($black, 0.1);
        border-radius: 4px;
      }

      a.nav-link {
        color: $black !important;
        p {
          color: $black;
        }
      }
    }

    .navbar-text,
    .navbar-brand {
      color: $black;
    }

    .form-control {
      color: $black !important;
    }

    .form-control::placeholder {
      color: $dark-gray !important;
    }
  }

  &.bg-dark {
    background: $black !important;
  }

  &.bg-primary {
    background-color: $primary !important;
  }

  &.bg-warning {
    background-color: $warning !important;
  }

  &.bg-info {
    background-color: white !important;
    z-index: 99;
    box-shadow: 0px 15px 35px rgba(0, 0, 0,0.1);
    .nav-bar-active {
      a {
        color: $primary-color;
      }
    }
    @media (min-width: 992px) {
      .nav-bar-active {
        a {
          color: $primary-color !important;
        }
      }
    }
    a {
      color: #111121;
      font-size: 15px;
      font-weight: 500;
    }
     .nav-link:hover {
      color: $primary-color !important;
      opacity: 1;
    }

    .nav-link:hover.nav-link:before {
      position: absolute;
      left: 0;
      width: 100%;
      margin: 0 auto;
      bottom: 0;
      opacity: 1;
      height: 3px;
      -webkit-animation: rotateline 7s infinite linear;
      content: "";
      border-radius: 5px;
      animation: rotateline 7s infinite linear;
      background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
    }
  }

  &.bg-success {
    background-color: darken($success, 10%) !important;
  }

  &.bg-danger {
    background-color: $danger !important;
  }

  .navbar-brand {
    position: relative;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    color: $white;
    text-transform: capitalize;
    font-size: $font-paragraph;
    span {
      font-weight: $font-weight-bold;
    }
  }

  .navbar-toggle button:focus,
  .navbar-toggler {
    outline: none;
  }
}
.bg-info{
.annex-logo {
  height: 65px;
  border-radius:0px;
}
}
.navbar-transparent{
  .annex-logo {
    // width: 243px;
    border-radius:0px;
  }
}

@media (max-width: 1200px) {
  .annex-logo {
    height: 45px !important;
  }
}

.navbar-minimize-fixed {
  position: fixed;
  margin-left: 40px;
  margin-top: 14px;
  transition: 0.3s ease;
  color: white;
  z-index: 20;
  opacity: 0;
  transition: 0.2s ease;
  button {
    i {
      font-size: 18px;
    }
  }
}

.notification {
  background: $danger;
  color: $white;
  border-radius: $border-radius-xl;
  height: 6px;
  width: 6px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  top: 10px;
  right: 10px;
  border: 1px solid $danger;
}

.navbar-nav li {
  padding: 15px 10px !important;
  a {
    color:#1D1D1F;
    font-size: 15px;
    font-weight: 500px;
    letter-spacing: 1px;
  }
  i {
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
  }
}

.navbar-collapse-header {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }

      &.collapsing,
      &.collapse {
        animation: show_navbar_collapse 0.2s ease forwards;
      }

      &.collapsing-out {
        animation: hide_navbar_collapse 0.2s ease forwards;
      }

      .navbar-collapse-header {
        display: block;
        padding-bottom: 1rem;

        .collapse-brand {
          font-size: $font-size-xl;
          color: $white;
          a {
            font-weight: $font-weight-bold;
          }
        }

        .collapse-close {
          .navbar-toggler {
            color: $white;
            font-size: $font-size-xl;
          }
        }
      }

      .navbar-nav li {
        padding: 0px;
      }
    }

    .navbar-nav {
      .btn {
        margin-left: -3px;
        display: flex;

        i {
          margin-right: 12px;
        }

        span {
          margin: 0;
          text-transform: uppercase;
          font-weight: 300;

          &,
          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            color: $black !important;
          }
        }
      }

      a.nav-link {
        i {
          opacity: 1;
          margin-right: 5px;
        }
        p {
          display: inline-block;
          margin-left: 7px;
          color: $white;
        }
      }

      .modal-search .modal-dialog {
        padding: 0 40px;
      }
      .dropdown {
        margin: 5px 0;

        .dropdown-menu {
          .dropdown-item {
            margin-top: 0;
            padding-left: 24px;
          }
        }
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
      li a {
        color: $black;
      }
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 0.7rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default;
    }

    button.navbar-toggler[data-target="#navigation"] {
      padding-top: 0;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 80%;
      margin: auto;
      height: 2px;
      border-radius: 1px;
      background: #fff;

      &.navbar-kebab {
        height: 4px;
        width: 4px;
        margin-bottom: 3px;
        border-radius: 50%;
      }

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 0px;
      }

      &.bar2 {
        width: 80%;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default;
    }

    .navbar-brand {
      margin-left: 20px;
      position: relative;
    }
  }
  @media screen and (max-width: 576px) {
    .navbar.navbar-transparent {
      padding: 15px 0 15px;
    }
    .navbar.bg-info{
      padding:15px 0;
      align-items: center;
      box-shadow: 0px 15px 35px rgba(17, 10, 10, 0.1);
      z-index:9999;
    }
    .navbar .navbar-brand {
      margin-left: 0px;
    }
    .abouthome-title_n {
      
      h2{
        font-size:28px;
        line-height:36px;
      }
    }
    .news-main{
      padding: 20px 0px;
    }
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.tim-icons {
        opacity: 0.5;
      }
    }
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .navbar {
    .caret {
      position: absolute;
      left: 80%;
      top: 55%;
      margin-left: 0;
    }
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 991px) {
  .navbar-expand-lg {
    .container {
      padding: 0px 15px;
    }
  }
  .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  .navbar-collapse {
    // position: absolute;
    // width: calc(100% - 1.4rem);
    width: 100%;
    height: auto !important;
    left: 0;
    top: 0;
    // margin: 0.7rem;
    background: #141414;
    // border-radius: $border-radius-sm;
    // padding: 1.4rem;
    max-height: 400px;
    margin-top:0px;
  }
}
@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }
}

.navigation-example {
  .navbar {
    z-index: 2;
  }
}

@keyframes show_navbar_collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide_navbar_collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
.ht-nav-toggle {
  float: right;
  z-index: 99;
  position: relative;
  display: block;
  margin: 0 auto;
  color: #1D1D1F !important;
  cursor: pointer;
  margin-top: 0;
  text-decoration: none;
  span {
    background: #1D1D1F;
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    width: 30px;
    height: 2px;
    text-transform: uppercase;
    text-indent: -55px;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
}
.ht-nav-toggle span::before {
  background: #1D1D1F;
  top: -7px;
  width: 20px;
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  right: 0;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}
.ht-nav-toggle span::after {
  background: #1D1D1F;
  content: "";
  width: 40px;
  height: 2px;
  position: absolute;
  right: 0;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  bottom: -7px;
}
.modal {
  display: block !important;
  font-size: 12px;
  position: absolute !important;
  padding: 50px 10px;
  width: 350px !important;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  z-index: 99999;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.popup-overlay {
  background-color: transparent !important;
}
.modal {
  .header1 {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  span {
    color: black;
  }
}
.modal {
  .content {
    width: 100%;
    padding: 10px 5px;
  }
}
.modal {
  .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
}

  .close {
    top: 20px !important;
    height: 44px;
    width: 44px;
    cursor: pointer;
    position: absolute;
    opacity: 1;
    transition: 0.3s;
    right: 20px;
    font-size: 24px;
    background: #ffffff;
    width: 40px;
      top: 0px !important;
      left: 0 !important;
      margin: 0 !important;
      line-height: 0;
      text-indent: 0;
      background: 0 0;
      position: relative;
      display: inline-block;
      zoom: 1;
      height: 35px;
      opacity: 1;
      text-transform: uppercase;
      transition: all 0.2s ease-out;
    // span {
    //   width: 40px;
    //   top: 0px !important;
    //   left: 0 !important;
    //   margin: 0 !important;
    //   line-height: 0;
    //   text-indent: 0;
    //   background: 0 0;
    //   position: relative;
    //   display: inline-block;
    //   zoom: 1;
    //   height: 2px;
    //   opacity: 1;
    //   text-transform: uppercase;
    //   transition: all 0.2s ease-out;
    // }
 
}
.close::before {
  width: 40px;
  background: #141414;
  top: 15px;
  transform: rotateZ(45deg);
  content: "";
  position: absolute;
  right: 0;
  height: 2px;
  transition: 0.1s;
  opacity: 1;
  cursor: pointer;
}
.close::after {
  width: 40px;
  cursor: pointer;
  background: #141414;
  top: 15px;
  transform: rotateZ(-45deg);
  content: "";
  width: 40px;
  height: 2px;
  position: absolute;
  right: 0;
  transition: 0.1s;
  opacity: 1;
}

.button-pop {
  background-color: transparent;
  border: transparent;
}
.table-view {
  display: none;
}


@media screen and (max-width: 768px) {
  .table-view {
    display: inline-block;
    margin-left: 8px;
  }
  .distop-view {
    visibility: hidden;
    display: none;
  }
}
@media screen and(max-width:576px) {
  .button-pop {
    visibility: hidden;
    display: none;
  }
  .table-view {
    display: none;
  }
  .navbar-toggler{
    margin-right: 1rem;
  }
}
.popup-content {
  position: fixed !important;
  width: 350px !important;
  top: 0;
  bottom: 0;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  right: 0;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.close:hover {
  opacity: 1 !important;
  span::before {
    background-color: #3414bc;
    opacity: 1;
  }
  span::after {
    background-color: #3414bc;
    opacity: 1;
  }
}
.side-logo {
  height: auto;
}
.side-text {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 1.7;
  color: #4f4e4e;
  letter-spacing: 1px;
  margin-top: 0;
}

.title {
  position: relative;
  color: #1c2045 !important;
  margin-top: 0 !important;
  padding-bottom: 20px;
  margin-bottom: 25px !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 30px;
  text-transform: capitalize;
  letter-spacing: 2px;
}
.title::before {
  position: absolute;
  left: 0;
  width: 100px;
  margin: 0 auto;
  bottom: 0;
  height: 3px;
  animation: rotateline 7s infinite linear;
  -webkit-animation: rotateline 7s infinite linear;
  content: "";
  border-radius: 5px;
  background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
.contact-info li {
  span {
    font-size: 16px;
    color: #111121;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #4f4e4e;
    letter-spacing: 1px;
  }
  a {
    margin-bottom: 0;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #4f4e4e;
    letter-spacing: 1px;
    text-decoration: none !important;
  }
}
.site-social-icon {
  ul {
    padding: 0;
    margin: 0;
    line-height: 0;
  }
  li {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
  }

  a {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff !important;
    text-align: center;
    margin: 0;
    border-radius: 4px;
    border: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
   
  }
}
.site-contactus-icon {
  padding-left: 50px;
  position: relative;
  i {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 24px;
    font-size: 30px;
    color:$primary-color;
    text-align: center;
  }
}
.social-icons-fackbook {
  background-color: #5d82d1;
  &:hover{
    color: #fff !important;
    &::before{
      border: 0;
      height: 0 !important;
    }
  }
}
.social-icons-twitter {
  background-color: #40bff5 !important;
}
.social-icons-linkedin {
  background-color: #238cc8;
}
.social-icons-skype {
  background-color: #13c1f3;
}
.flaticon-location:before {
  font-size: 32px !important;
}
.flaticon-phone-ringing::before {
  font-size: 32px !important;
}
.flaticon-email::before {
  font-size: 32px !important;
}
@media screen and(max-width:992px) {
  .nav-bar-active {
    a {
      background: $primary-color;
      padding: 10px 20px !important;
      font-size: 15px;
      color: #ffffff;
      border-radius: 0;
      width: 100%;
    }
  }
  .nav-bar-active a::before {
    display: none;
  }
  .navbar-nav li {
    padding: 0px !important;
    a {
      padding: 10px 20px !important;
      font-size: 15px;
      color: #ffffff;
      border-radius: 0;
      width: 100%;
      line-height: 1.7 !important;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  .bg-info {
    a {
      color: white !important;
    }
  }
}
.nav-bar-active {
  a::before {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    -webkit-animation: rotateline 7s infinite linear;
    content: "";
    border-radius: 5px;
    animation: rotateline 7s infinite linear;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
  }
}
// .nav-bar-active {

//   a:hover {
//     color: #3414bc;
//     position: absolute;
//     left: 0;
//     width: 100%;
//     margin: 0 auto;
//     bottom: 0;
//     height: 3px;
//     -webkit-animation: rotateline 7s infinite linear;
//     content: "";
//     border-radius: 5px;
//     animation: rotateline 7s infinite linear;
//     background: linear-gradient(
//       to right,
//       rgba(255, 120, 16, 1) 0%,
//       rgba(255, 21, 106, 1) 25%,
//       rgba(255, 94, 44, 1) 50%,
//       rgba(187, 13, 76, 1) 75%,
//       rgba(69, 204, 137, 1) 100%
//     );
//   }
// }

// active color for home and other route
.color-black {
  color: #111121 !important;
}
@media screen and(max-width:992px) {
  .color-black {
    color: white !important;
  }
  .nav-link:hover.nav-link:before {display: none;}
 
.navbar{
  .navbar-nav{
    .nav-item{
      .nav-link:hover {
        color: #fff;
    }
    }
  }
} 

}

.nav-popup{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 30px 10px;
  width: 350px;
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);

}


.nav-popup::-webkit-scrollbar
{
	width:0px;
}



.showside{
  .nav-popup{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}