.news-main {
    padding: 80px 0px;
    background-image: url(../../../img/brand_bg.webp);
    background-size: cover;
    // background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff;
    color: #1c2045;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
  
    .iq-waves {
        position: absolute;
        width: 24rem;
        height: 24rem;
        left: -15rem;
        bottom: -5rem;
        z-index: 2;
        opacity:0.1;

        .waves {
            position: absolute;
            width: 35rem;         
            height: 35rem;
            background: #86BC24;
            opacity: 0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            border-radius: 320px;
            background-clip: padding-box;
            -webkit-animation: waves 5s ease-in-out infinite;
            animation: waves 5s ease-in-out infinite;
        }
        .wave-1 {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }
        .wave-2 {
            -webkit-animation-delay: 1s;
            animation-delay: 2s;
        }
        .wave-3 {
            -webkit-animation-delay: 2s;
            animation-delay: 3s;
        }
        @-webkit-keyframes waves {
            0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
            50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
            100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
          }
          @keyframes waves {
            0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
            50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
            100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
          }
    }
    .iq-objects-03 {
        position: absolute;
        right: -5%;
        top: -8%;
    }
}

.Home-customer-title {
    padding-bottom: 0px !important;
}

.News-title {
    padding-bottom: 50px;
    
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 38px;
        font-style: normal;
        letter-spacing: 2px;
        line-height: 46px;
        font-weight: 600;
        span{
            color: #86bc24;
        }
    }
    p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #111121 !important;
        line-height: 1.9;
    }
}
@media (max-width: 767px) {
    .News-title {
        h2 {
            font-size: 34px;
            line-height: 40px;
        }
        p {
            font-size: 16px;
        }
    }
}
@media (max-width: 576px) {
    .News-title h2 {
        font-size: 28px;
        line-height: 36px;
    }
}
.news-title-head:before {
    position: absolute;
    left: 50%;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 07s infinite linear;
    -webkit-animation: rotateline 07s infinite linear;
    content: "";
    border-radius: 5px;

    background: -moz-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, rgba(255, 120, 16, 1)),
        color-stop(25%, rgba(255, 21, 106, 1)),
        color-stop(50%, rgba(255, 94, 44, 1)),
        color-stop(75%, rgba(187, 13, 76, 1)),
        color-stop(100%, rgba(255, 120, 16, 1))
    );
    background: -webkit-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -o-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -ms-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7810',endColorstr='#ff7810',GradientType=1 );
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}
.news-post-img {
    width: 100% !important;
    height: 100% !important;
}
.news-post {
    position: relative;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}
.news-post-details {
    transition: all 0.5s ease-in-out 0s;
    padding: 30px 30px 70px;
    background: #fff;
    margin-bottom: 25px;
    transition: all 0.5s ease-in-out 0s;
    h5 {
        margin: 15px 0 0;
        text-transform: capitalize;
        font-weight: 500;
        a {
            transition: all 0.5s ease-in-out 0s;
            color: #111121;
            display: inline-block;
            font-size: 22px;
            letter-spacing: 2px;
            font-weight: 500;
            line-height: 26px;
        }
    }
    p {
        margin: 15px 0px;
        font-size: 15px;
        line-height: 1.9;
        color: #111121;
        letter-spacing: 1px;
        font-weight: 300;
    }
}
@media (max-width: 1200px) {
    .news-post-details h5 a {
        font-size: 18px;
        line-height: 24px;
    }
}
@media (max-width: 992px) {
    .news-post-details a {
        font-size: 18px;
        line-height: 28px;
    }
}
.post-author {
    position: absolute;
    left: 30px;
    bottom: -25px;
    span {
        color: $primary-color;
        font-size: 16px;
        font-weight: 500;
        padding-left: 15px;
        padding-top: 15px;
        display: inline-block;
    }
}
.post-author-img {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: top;
}
.news-post-details a:hover {
    color: $primary-color;
}
.news-client {
    padding-top: 50px;
}
.client-div {
    align-items: center;    
    display: flex;
    
    .owl-item{
    margin:0px;
}
.owl-item:hover{
z-index: 99;    
}

    .clients-logo {
    background: #fff;
    border: 0px;
    padding: 25px 15px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius:5px;
    cursor: pointer;
    transition-duration: 0.5s; 
    margin: 20px 10px;
    display: flex;
    height: 200px;
    align-items: center;
    overflow: hidden;
    border-bottom: 2px solid #86bc24;

        img {
                max-width: 100% !important;
                margin: 0 auto !important;
                border-radius: 0px !important;
                width: auto !important;
                transition: all 0.5s ease-out 0s;              
                -webkit-transition: all .8s ease-in-out;
                object-fit: contain !important;
                width: 100% !important;
                height: 100% !important;
        }
       



        @-webkit-keyframes wobble-horizontal {
            16.65% { -webkit-transform: translateX(8px); transform: translateX(8px); }
            33.3% { -webkit-transform: translateX(-6px); transform: translateX(-6px); }
            49.95% { -webkit-transform: translateX(4px); transform: translateX(4px); }
            66.6% { -webkit-transform: translateX(-2px); transform: translateX(-2px); }
            83.25% { -webkit-transform: translateX(1px); transform: translateX(1px); }
            100% { -webkit-transform: translateX(0); transform: translateX(0); }
            }
            @keyframes wobble-horizontal {
            16.65% { -webkit-transform: translateX(8px); transform: translateX(8px); }
            33.3% { -webkit-transform: translateX(-6px); transform: translateX(-6px); }
            49.95% { -webkit-transform: translateX(4px); transform: translateX(4px); }
            66.6% { -webkit-transform: translateX(-2px); transform: translateX(-2px); }
            83.25% { -webkit-transform: translateX(1px); transform: translateX(1px); }
            100% { -webkit-transform: translateX(0); transform: translateX(0); }
            }   
        
    }
    .clients-logo:hover img {          
        -webkit-animation-name: wobble-horizontal;
        animation-name: wobble-horizontal;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
      

    }
    .clients-logo:hover{
        border-bottom: 5px solid #86bc24;
    }

}


@media(max-width:767px){
    .clients-logo{
    width: calc(100%/2);
    padding: 20px;
    }

.owl-carousel {
    .owl-stage-outer{
    
    padding: 0 5px;
}
}
.news-main{
    .iq-waves{
    left: -12rem;
    bottom: -15rem;
    }  
}
.news-main {
    .iq-waves{
        .waves{
            width: 20rem;
            height: 20rem;
        }
    }
}
.owl-carousel{
.owl-item{
.clients-logo{    
    img{
         width:auto !important;
    } 
}  
.clients-logo{
height:100px !important;    
}     
}    
}
.news-client{
    padding-top:0px;
}

}
