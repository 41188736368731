
$asset-base-path: '../../../../assets' !default;

// Core

@import "blk-design-system-react/custom/functions";
@import "blk-design-system-react/bootstrap/functions";

@import "blk-design-system-react/custom/variables";
@import "blk-design-system-react/bootstrap/variables";
@import "blk-design-system-react/custom/mixins";
@import "blk-design-system-react/bootstrap/mixins";

// Bootstrap components

@import "blk-design-system-react/bootstrap/root";
@import "blk-design-system-react/bootstrap/reboot";
@import "blk-design-system-react/bootstrap/type";
@import "blk-design-system-react/bootstrap/grid";
@import "blk-design-system-react/bootstrap/forms";
@import "blk-design-system-react/bootstrap/buttons";
@import "blk-design-system-react/bootstrap/transitions";
@import "blk-design-system-react/bootstrap/nav";
@import "blk-design-system-react/bootstrap/navbar";
@import "blk-design-system-react/bootstrap/card";
@import "blk-design-system-react/bootstrap/close";
@import "blk-design-system-react/bootstrap/modal";

@import "blk-design-system-react/bootstrap/carousel";
@import "blk-design-system-react/bootstrap/utilities";
@import "blk-design-system-react/bootstrap/print";

// Custom components
@import "blk-design-system-react/custom/chat.scss";
@import "blk-design-system-react/custom/loader.scss";
@import "blk-design-system-react/custom/comman.scss";
@import "blk-design-system-react/custom/PageHeader.scss";
@import "blk-design-system-react/custom/service-home.scss";
@import "blk-design-system-react/custom/FAQ.scss";
@import "blk-design-system-react/custom/TAC.scss";
@import "blk-design-system-react/custom/PrivacyPolicy.scss";
@import "blk-design-system-react/custom/wedo.scss";
@import "blk-design-system-react/custom/scroll_to_top.scss";
@import "blk-design-system-react/custom/Hireus.scss";
@import "blk-design-system-react/custom/aboutuspage.scss";
@import "blk-design-system-react/custom/services.scss";
@import "blk-design-system-react/custom/technologiespage.scss";
@import "blk-design-system-react/custom/news.scss";
@import "blk-design-system-react/custom/servicepage.scss";
@import "blk-design-system-react/custom/testimonials.scss";
@import "blk-design-system-react/custom/AboutusHome.scss";
@import "blk-design-system-react/custom/workhere.scss";
@import "blk-design-system-react/custom/technologes.scss";
@import "blk-design-system-react/custom/buttons.scss";
@import "blk-design-system-react/custom/dropdown.scss";
@import "blk-design-system-react/custom/Index-header.scss";
@import "blk-design-system-react/custom/video.scss";
@import "blk-design-system-react/custom/footer.scss";
@import "blk-design-system-react/custom/forms.scss";
@import "blk-design-system-react/custom/images.scss";
@import "blk-design-system-react/custom/modal.scss";
@import "blk-design-system-react/custom/navbar.scss";
@import "blk-design-system-react/custom/type.scss";
@import "blk-design-system-react/custom/icons.scss";
@import "blk-design-system-react/custom/tables";
@import "blk-design-system-react/custom/checkboxes-radio";
@import "blk-design-system-react/custom/fixed-plugin";
@import "blk-design-system-react/custom/pagination.scss";
@import "blk-design-system-react/custom/misc.scss";
@import "blk-design-system-react/custom/rtl.scss";
@import "blk-design-system-react/custom/input-group.scss";
@import "blk-design-system-react/custom/example-pages.scss";
@import "blk-design-system-react/custom/progress.scss";
@import "blk-design-system-react/custom/badge.scss";
@import "blk-design-system-react/custom/pills.scss";
@import "blk-design-system-react/custom/tabs.scss";
@import "blk-design-system-react/custom/info-areas.scss";
@import "blk-design-system-react/custom/casedetail.scss";

// Sections
@import "blk-design-system-react/custom/sections.scss";


body{
    font-family: 'Poppins', sans-serif !important;
}

.container {
    max-width: 1170px;
}   