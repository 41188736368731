.service-main {
    background-image: url(../../../img/9.webp);
    padding: 80px 0px;
    background-color: #f3f6ff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 0.5s ease-in-out 0s;
    background-repeat: repeat !important;
    background-size: auto !important;
    animation: animatedBackground 20s linear infinite;
    -ms-animation: animatedBackground 20s linear infinite;
    -moz-animation: animatedBackground 20s linear infinite;
    -webkit-animation: animatedBackground 20s linear infinite;
}
.service-main {
    .react-tabs__tab-list {
        margin: 0px 0px 0px 0px !important;
        margin-left: 10px;
    }
    .react-tabs__tab-panel--selected{
        width: 100%;
        overflow: hidden;
        margin-left: 0 !important;
    }
}
.service-main {
    .react-tabs__tab {
        margin: 0px;
        border: none;
        padding: 5px 5px;
        width: calc(100% / 12);
        text-align:center;
     img{
         width:40px;
         padding-top: 10px;
     }  
     h5{
        font-size: 12px;
        color: #000;
        margin:8px 0px;
     } 
    }
}
.service-main {
    .react-tabs__tab{
        border-top: 3px solid transparent;
    }
    .react-tabs__tab--selected {
        //  background-color: #f9f9f9;
        background-color: #ffff !important;
        // border-top: 3px solid transparent;
        border-top-color:$primary-color !important;
        // border-top: 3px solid  #3414bc;
        border-radius: 0px;
    }
}
.service-main {
    .react-tabs__tab:hover {
        background-color: #ffff !important;
        border-top: 3px solid transparent;
        border-top-color: $primary-color;
        border-radius: 0px;
    }
}
.services-detail {
    background: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 50px;
    margin-right: 2px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-height:600px;
    // transition: opacity .15s linear;
    p {
        font-size: 15px;
        color: #1c2045;
        letter-spacing: 1px;
        margin-bottom: 1rem;
        text-align: left;
    }
}
// .fade{
//     transition: opacity .15s linear;
// }
@media (max-width: 767px) {
    .services-detail {
        padding: 30px;
    }
}
.services-title {
    position: relative;
    color: #1c2045;
    text-align: left;
    padding-bottom: 20px;
    letter-spacing: 2px;
    margin-bottom: 25px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
@media (max-width: 1200px) {
    .services-title {
        font-size: 20px;
        line-height: 30px;
    }
}
@media (max-width: 992px) {
    .services-title {
        font-size: 20px;
        line-height: 26px;
    }
}
@media (max-width: 576px) {
    .service-main{
        padding:20px 0px;
    }
    .services-title {
        font-size: 18px;
        line-height: 26px;
    }
    .react-tabs__tab{
        h5{
        display:none;
        }
        img{
            padding:5px 0px !important;
        }
        
        }
        .round-animation{
            overflow: hidden;
        
            .svg_upper{
                img{
                    left: 7rem;
                    bottom: -3rem;           
                    transform: scale(1.6);  
                }
            }
        }
}

.services-title:before {
    position: absolute;

    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 07s infinite linear;
    -webkit-animation: rotateline 07s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: -moz-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, rgba(255, 120, 16, 1)),
        color-stop(25%, rgba(255, 21, 106, 1)),
        color-stop(50%, rgba(255, 94, 44, 1)),
        color-stop(75%, rgba(187, 13, 76, 1)),
        color-stop(100%, rgba(255, 120, 16, 1))
    );
    background: -webkit-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -o-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: -ms-linear-gradient(
        left,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7810',endColorstr='#ff7810',GradientType=1 );
}
.services-list li {
    position: relative;
    padding-left: 40px;
    i {
        position: absolute;
        left: 0;
        top: 0;
        color: $primary-color;
        font-size: 30px;
        line-height: 30px;
    }
    p {
        color: #1c2045;
        margin-bottom: 0px;
    }
}
.fa-check:before {
    content: "\f00c";
}
.bank-services {
    margin-top: 20%;
}
