// .Index-header-bg {
//     position: relative;
//     min-height: 800px;
//     height: 981px;
//     overflow: hidden;
//     color: #ffffff;
//     z-index: 0;
//     background: linear-gradient(30deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 90%);
// }

.Index-header-bg {
    position: relative;
    min-height: 400px;
    height: 740px;
    overflow: hidden;
    color: #ffffff;
    z-index: 0;
    background: linear-gradient(30deg, white 0%, white 90%);
   
    .indexheader-bg-img{
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
   }
   
    svg {
        #followPath {
            fill: none;
            stroke: #86bc24;
            stroke-width: 8;
            stroke-dasharray: 2870px;
            stroke-dashoffset: 2870px;
            -webkit-animation: drawPath 6s linear infinite;
            animation: drawPath 6s linear infinite;
        }
         #dashedPath {
            fill: none;
            stroke: #fff;
            stroke-width: 14;
            stroke-dasharray: 50px;
        } 
        #airplain {
            fill: #86bc24;
            -webkit-transform: translate(-2px, -45px);
            transform: translate(-2px, -45px);
        }
    }
    .ani_right {
        height: 320px;
        width: 320px;
        right: 0;
        top: 0;
        position:absolute;
    }
    @-webkit-keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }
        @keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }


}


@media (max-width: 992px) {
    .Index-header-bg {
        height: auto !important;
        // padding: 200px 0 100px !important;
        padding: 125px 0 100px !important;
        min-height: inherit;
    }
}
// .Page-container {
//     position: absolute;
//     top: 40%;
//     width: 100%;
//     z-index: 9;
//     transform: translateY(-50%);
//     -webkit-transform: translateY(-50%);
//     padding-top: 126px;
// }
.Page-container {
    position: absolute;
    top: 35%;
    width: 100%;
    z-index: 9;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding-top: 400px;
}
@media (max-width: 992px) {
    .Page-container {
        position: inherit;
        transform: inherit;
        padding-top: 0 !important;
    }
}
@media (max-width: 767px) {
    .sm-mt-5 {
        margin-top: 50px !important;
    }
}
.page-heading {
    font-size: 60px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    color: #111121;
    padding-left: -15px !important;
    text-align: -webkit-auto;
    line-height: 70px;
    span {
        color: #86BC24;
    }
}

@media (max-width: 992px) {
    .page-heading {
        font-size: 50px;
        line-height: 60px;
    }
}

.heading-detail {
    text-align: -webkit-auto;
    // font-family: sans-serif, "Poppins";
    font-size: 19px;
    margin: 0px 0px 16px;
    color: #111121;
    font-weight: 400;
    letter-spacing: 1px;
}
@media (max-width: 992px) {
    .heading-detail {
        font-size: 14px;
        line-height: 26px;
    }
}
.heading-btn {
    background: $white-color;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    color: #111121;
    font-size: 1rem;
    text-align: -webkit-auto;
    position: relative;
    overflow: hidden;
    padding: 12px 25px;
    letter-spacing: 1px;
    line-height: 1.5;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;

    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    span {
        transition: all 0.5s ease-in-out 0.5s;
        color: $black-color;
    }
}
.heading-us {
    padding: 16px 1px;
    margin-top: 2px;
}
.heading-us:hover {
    .heading-btn {
        background: $black-color !important;
        color: $white-color !important;
        animation-name: jello;
        animation-duration: 1s;
        animation-fill-mode: both;
       
        span {
            transition: all 0.5s ease-in-out 0.5s;
            color: $white-color !important;
        }
    }
}
.heading-row {
    padding-left: -15px;
}
@media (max-width: 768px) {
    .indexheader-bg-img {
        display: none;
    }
}
.index-header-img1 {
    display: inline-block;
    max-width: 100%;
    position: relative;
    -webkit-animation: seo 2s linear infinite;
    animation: seo 2s linear infinite;
    transition: all 0.5s ease-in-out 0s;
    // transform: scale(1);
}
@keyframes seo {
    0% {
        left: 20px;
        bottom: 20px;
    }
    25% {
        left: -20px;
        bottom: 20px;
    }
    50% {
        left: -20px;
        bottom: 0;
    }
    75% {
        left: 0;
        bottom: 0;
    }
    100% {
        left: 20px;
        bottom: 20px;
    }
}
@keyframes jello {
    0%,
    11.1%,
    100% {
        -webkit-transform: none;
        transform: none;
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
}

@media (max-width: 576px) {
    .page-heading {
        font-size: 30px;
        line-height: 36px;
    }
   
    .Index-header-bg {
        .ani_right {
            height: 120px;
            width: 120px;          
        } 
        padding: 60px 0 10px !important;
    }

}
