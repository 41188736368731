.aboutuspage-bg-white {
    background-color: #ffffff;

position: relative;


  
}
.aboutuspage-header {
    background: #f8f8fb url(../../../img/08.webp);
    display: block;
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    text-align: center;
    
    svg {
        #followPath {
            fill: none;
            stroke: #86bc24;
            stroke-width: 8;
            stroke-dasharray: 2870px;
            stroke-dashoffset: 2870px;
            -webkit-animation: drawPath 6s linear infinite;
            animation: drawPath 6s linear infinite;
        }
         #dashedPath {
            fill: none;
            stroke: #fff;
            stroke-width: 14;
            stroke-dasharray: 50px;
        } 
        #airplain {
            fill: #86bc24;
            -webkit-transform: translate(-2px, -45px);
            transform: translate(-2px, -45px);
        }
    }
    .ani_right {
        height: 320px;
        width: 320px;
        right: 0;
        top: 0;
        position:absolute;
    }
    @-webkit-keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }
        @keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }

        .ani.ani-5 {
            left: 5%;
            top: 10%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            position:absolute;
       
        .moving-square-frame {
            position: absolute;
            height: 40px;
            width: 40px;
            top: 10px;
            left: 10px;
            opacity: .4;
            border: 1px solid #1d1d1f;
        }
        .ani-moving-square {
            border: 1px solid #86bc24;
            height: 40px;
            width: 40px;
            opacity:1;
            -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
            animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        }
        @-webkit-keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
            @keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
    }


.ani_inner_banner{
    left: 100px;
    bottom: 100px;
    -webkit-transform: translate(-50%, 50%) scale(0.8);
    transform: translate(-50%, 50%) scale(0.8);
    position: absolute;

    .ani-cube {
        -webkit-perspective: 20000px;
        perspective: 20000px;
        height: 100px;
        width: 100px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        -webkit-animation: ani-cube-1 20s linear infinite;
        animation: ani-cube-1 20s linear infinite;
    }

    .ani-cube.ani-cube-3 {
        -webkit-transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  

        .cube-face.face_back {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, -50px);
            transform: translate3d(0px, 0px, -50px);
            z-index: 1;
        }
        .cube-face {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 100px;
            height: 100px;
        }  

       
       
        .cube-face.face_front {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, 50px);
            transform: translate3d(0px, 0px, 50px);
            z-index: 10;
        }
        .cube-face.face_right {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 8;
        }
        .cube-face.face_left {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 9;
        }
        .cube-face.face_top {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 11;
        }
        .cube-face.face_bottom {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 5;
        }
        
        .cube-face.face_front {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_right {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_left {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_top {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_bottom {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_back {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }             

        @-webkit-keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            @keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            
    }

}        


}
@media (max-width: 992px) {
    .aboutuspage-header {
        padding: 160px 0px;
    }
}
@media (max-width: 576px) {
    .aboutuspage-header {
        padding: 160px 0 110px;
    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}
.aboutuspage-title {
    font-size: 50px;
    color: #1c2045;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 20px;
}
.aboutuspage-title::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform:translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 07s infinite linear;
    -webkit-animation: rotateline 07s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@media (max-width: 767px) {
    .aboutuspage-title {
        font-size: 30px;
        line-height: 30px;
    }
}
@media (max-width: 576px) {
    .aboutuspage-title {
        font-size: 24px;
    }
}

.aboutuspage-title-pattern-img {
    vertical-align: middle;
}
.aboutuspage-title-pattern {
    bottom: 0;
    position: absolute;
}
.aboutuspage-conten {
    padding: 80px 0px;
    // background: #ffffff url(../../../img/23.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-top: 1px solid #86bc24;
    span{
        color:#86bc24;
    }
}
.about-us-img {
    width: 100%;
    border-radius: 5px;
}
.aboutus-title {
    h6 {
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        background: $black-color;
        color: #ffffff;
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        color: #1c2045;
        text-transform: capitalize;
        margin-bottom: 10px;
        position: relative;
        letter-spacing: 2px;
    }
}
@media (max-width: 767px) {
    .aboutus-title h2 {
        font-size: 30px;
        line-height: 30px;
    }
}
@media (max-width: 576px) {
    .aboutus-title h2 {
        font-size: 24px;
    }
}

.aboutus-title-details {
    color: #111121 !important;
    line-height: 1.9;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
}
.aboutus-customer-list {
    list-style: none;
    display: inline-block;
    padding-left: 0px;
    margin: 0px;
    padding: 0;
    li {
        float: left;
        margin-bottom: 15px;
        width: calc(100% / 2);
        padding-left: 20px;
        position: relative;
        color: #4f4e4e;
        letter-spacing: 1px;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        line-height: 1.7;
    }
}
@media (max-width: 576px) {
    .aboutus-customer-list li {
        width: 100%;
    }
}
.aboutus-customer-list li:before {
    background-color: $black-color;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 10px;
    height: 2px;
    border-radius: 4px;
}
.aboutus-title-button {
    // background: linear-gradient(90deg, rgba(52, 20, 188, 1) 0%, rgba(201, 0, 139, 1) 90%) !important;
    background:#1c2045;
    color: #fff !important;
    padding: 12px 25px;
    font-weight: 500;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 5px;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 9;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 1px;
}
@media screen and(max-width:576px) {
    .aboutus-title-button {
        padding: 10px 20px;
    }
}
.aboutus-title-button:hover {    
    color: #fff !important;
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background: #1c2045;
}
@keyframes jello {
    0%,
    11.1%,
    100% {
        -webkit-transform: none;
        transform: none;
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
.aboutuspage-counter {
    background-color: #ffffff;
    padding: 80px 0px;
}
.aboutus-success-stories {
    background-color: #141414;
    background-image: url(../../../img/24.png);
    padding-bottom: 180px !important;
    padding: 80px 0px;
    -webkit-animation: animatedBackground 20s linear infinite;
    background-repeat: repeat !important;
    background-size: auto !important;
    animation: animatedBackground 20s linear infinite;
}
@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}
.sucess-stories {
    color: #fff;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        text-transform: capitalize;
        letter-spacing: 2px;
        color: #fff;
        margin-bottom: 20px;
    }
    h2 {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        margin-top: 0;
        letter-spacing: 2px;
        text-transform: capitalize;
        span{
        color:#86BC24 ;                
        }
    }
    p {
        font-size: 16px;
        color: #fff;
        line-height: 1.9;
        margin-top: 0;
        font-weight: 400;
        letter-spacing: 1px;
    }
}
.sucess-stories h2::before {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 7s infinite linear;
    -webkit-animation: rotateline 7s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}

@media (max-width: 767px) {
    .sucess-stories h2 {
        font-size: 34px;
        line-height: 40px;
    }
    .sucess-stories p {
        font-size: 16px;
    }
}
@media (max-width: 576px) {
    .sucess-stories h2 {
        font-size: 28px;
        line-height: 36px;
    }
}
@keyframes rotateline {
    from {
        background-position: 20px 20px, 30px 80px, 0 0;
    }
    to {
        background-position: 300px -90px, 30px 20px, 100% 0;
    }
}
.aboutus-slider {
    margin-top: -100px;
    overflow: hidden;
    z-index: 1;
    padding-top: 0px;
    padding-bottom: 60px;
}
.aboutusnews-client {
    padding-bottom: 80px;
}
.aboutus-services {
    background-color: #f3f6ff;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 80px 0px;
}
.pattern-3 {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
}

.aboutus-services-title {
    margin-bottom: 50px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        text-transform: capitalize;
        letter-spacing: 2px;
        margin-top: 0px;
    }
    h2 {
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 2px;
        margin-top: 0px;
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        span{
            color:#86BC24;
        }
    }
}

.aboutus-services-title h2::before {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotate 80s infinite linear;
    -webkit-animation: rotate 80s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7810',endColorstr='#ff7810',GradientType=1 );
}

.aboutus-services-items {
    padding: 40px 20px;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    img{
        width:80px;
    }
    h5 {
        margin: 30px 0 20px;
        text-transform: capitalize;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 2px;
        font-weight: 500;
        line-height: 26px;
        color:$primary-color;
    }
    p {
        color: #111121;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.9;
        margin-top: 0px;
        letter-spacing: 1px;
    }
}
.aboutus-services-items:hover {
    background-color: #fff;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767px) {
    .sm-mt-3 {
        margin-top: 30px !important;
    }
}
@media (max-width: 767px) {
    .aboutus-services-title h2 {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
    .aboutus-services-title h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

.creative-team {
    padding: 80px 0px;
    padding: 80px 0px;
    background: #ffffff url(../../../img/about_bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.creative-team-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.creative-team-title {
    margin-bottom: 50px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        color: #fff;
        margin-bottom: 20px;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        letter-spacing: 2px;
        margin-top: 0px;
        span{
            color:#86BC24;
        }
    }
}
.creative-team-title h2::before {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotate 80s infinite linear;
    -webkit-animation: rotate 80s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}

.testimonial.style-2{
	.testimonial-img {
		&:before {
			content: '';
			border: 10px solid #2575fc;
			width: 100%;
			height: 100%;
			-webkit-border-radius: 100%;
			border-radius: 100%;
			position: absolute;
			left: 0%;
			top: 0%;
			opacity: 1;
			-webkit-animation: 2s videomodule-anim linear infinite;
			animation: 2s videomodule-anim linear infinite;
		}
		&:after {
			content: '';
			border: 10px solid #2575fc;
			width: 100%;
			height: 100%;
			-webkit-border-radius: 100%;
			border-radius: 100%;
			position: absolute;
			left: 0%;
			top: 0%;
			opacity: 1;
			-webkit-animation: 2s videomodule-anim linear infinite;
			animation: 2s videomodule-anim linear infinite;
		}
		width: 35%;
        box-shadow:0px 0px 15px 0px rgba(72,73,121,0.15);
      

	}
  
    .names{       
        text-align:center;
        color:#1c2045; 
        font-weight:500;      
        display: block;
        box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);            
        transition: all 0.5s ease-in-out 0s;
        width: 150px;
        margin: 0 auto;
        margin-top: 0.8rem;
        
    }
   

	text-align: center;
	padding: 30px 30px 70px;
	&:before {
		display: none;
	}
	.testimonial-content {
		background: #ffffff;
		padding: 30px;
		border-radius: 7px;
		margin-top: 30px;
        
    -moz-box-shadow: 0px 0px 15px 0px rgba(72,73,121,0.15);
    box-shadow:0px 0px 15px 0px rgba(72,73,121,0.15);
		&:before {
			content: "";
			background: #ffffff;
			width: 90%;
			height: 50px;
			position: absolute;
            right: 0;
            margin: 0 auto;
            left: 0;
			bottom:50px;
			z-index: -1;
            box-shadow:0px 0px 15px 0px rgba(72,73,121,0.15);
		}
        h3{
    color: #1c2045;
    padding-bottom: 20px;
    margin-bottom: 25px;
    font-size: 30px;
    font-style: normal;
    line-height: 46px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 20px;

        }
	


	}
 
      
      
    
}


@-webkit-keyframes videomodule-anim {
    0% {
      -webkit-transform: scale(0.68);
              transform: scale(0.68); }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0; } }
  
  @keyframes videomodule-anim {
    0% {
      -webkit-transform: scale(0.68);
              transform: scale(0.68); }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0; } }
.testimonial-carousel {
	.controls {
		li.active {
			&:before {
				-webkit-animation-delay: .5s;
				animation-delay: .5s;
			}
		}
	}
}
.testimonial-img {
	border-radius: 50%;
	position: relative;
	display: inline-block;
	img {
		border-radius: 50%;
		position: relative;
		z-index: 9;
		width: 100%;
	}
}



.testimonial-2 {
	.carousel-inner {
		z-index: 9;
	}
	.controls {
		li {
			&:nth-child(1) {
				left: 0;
				bottom: 10%;
			}
			&:nth-child(2) {
				left: 5%;
				bottom: 55%;
			}
			&:nth-child(3) {
				right: 5%;
				bottom: 55%;
			}
			&:nth-child(4) {
				right: 0;
				bottom: 10%;
			}
		}
	}
}
.testimonial-carousel.testimonial-2 {
	.controls {
		position: absolute;
		height: 100%;
		width: 100%;
		bottom: 0;
		li {    
			position: absolute;			
           .item_img{			
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
			border-radius: 100%;						
			width: 130px;
			height: 130px;
			margin: inherit;
            overflow: hidden;
            animation-name: blinkblink;
			animation-duration: 5000ms;
			transform-origin: 70% 70%;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
            opacity: 0.6;
            float: none;            
            margin: 0 auto;
        }
       .names{
          text-align:center;
          color:#1c2045; 
          font-weight:500;
          margin-top: 0.8rem;
         display: block;
         box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);            
        transition: all 0.5s ease-in-out 0s;
       } 
		
    }
		li.active {
			opacity: 1;
			animation: inherit;
		}
	}


/*--zoom-fade--*/
.blinkblink {animation-name: blinkblink; animation-duration: 5000ms; animation-iteration-count: infinite; animation-timing-function: linear;}


@keyframes blinkblink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes blinkblink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes blinkblink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.zoom-fade, .featured-item:after{
    animation-name: zoom-fade; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: zoom-fade; 
    -webkit-animation-duration: 5s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: zoom-fade; 
    -moz-animation-duration: 5s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: zoom-fade; 
    -ms-animation-duration: 5s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: zoom-fade; 
    -o-animation-duration: 5s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

}



@media (max-width: 767px) {
    .creative-team-title h2 {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
    .creative-team-title h2 {
        font-size: 28px;
        line-height: 36px;
    }
}
.team-member {
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out 0s;
    img {
        width: 100%;
    }
}
.team-description {
    padding: 20px;
    text-align: center;
    background: #fff;
    transition: all 0.5s ease-in-out 0s;
    span {
        color: $primary-color;
        font-weight: 400;
        text-align: center;
        transition: all 0.5s ease-in-out 0s;
        font-size: 15px;
        letter-spacing: 1px;
    }
    h5 {
        margin: 0;
        text-transform: capitalize;
        font-weight: 500;
        color: #111121;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 2px;
    }
}

.team-member:hover {
    .team-description {
        transition: all 0.5s ease-in-out 0s;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
        .team-member-name {
            opacity: 1;
        }
    }
}
.team-member-name:hover {
    a {
        color: $primary-color;
        transition: all 0.5s ease-in-out 0s;
        box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    }
}
.team-member-name {
    display: inline-block;
    margin: 0 0 -18px;
    opacity: 0;
    transition: margin 0.2s ease 0s, opacity 0.2s ease 0s;
    h5 {
        margin: 0;
        text-transform: capitalize;
        font-weight: 500;
        color: #111121;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 2px;
        a {
            color: #111121;
            font-weight: 500;
        }
    }
}
.team-description-active {
    padding: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    span {
        color: $primary-color;
        font-weight: 400;
        text-align: center;
        transition: all 0.5s ease-in-out 0s;
        font-size: 15px;
        letter-spacing: 1px;
    }
    h5 {
        margin: 0;
        text-transform: capitalize;
        font-weight: 500;
        color: #111121;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 2px;
        a {
            box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
            margin: 16px 0 0;
            opacity: 1;
            font-weight: 500;
            transition: all 0.5s ease-in-out 0s;
            &:hover {
                color: $primary-color;
            }
        }
    }
}
.team-member-name-active {
    display: inline-block;
    margin: 0 0 -18px;
    transition: margin 0.2s ease 0s, opacity 0.2s ease 0s;
    h5 {
        margin: 0;
        text-transform: capitalize;
        font-weight: 500;
        color: #111121;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 2px;
        a {
            color: #111121;
        }
    }
}
.aboutus-testimonial {
    padding: 80px 0px;
    background: #f3f6ff url(../../../img/02.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
}
.aboutus-testimonial-title {
    margin-bottom: 50px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        text-transform: capitalize;
        letter-spacing: 2px;
        color: #fff;
        margin-bottom: 20px;
    }
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: capitalize;
      span{
        color: #86bc24;
      }  
    }
}

.aboutus-testimonial-title h2::before {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotate 80s infinite linear;
    -webkit-animation: rotate 80s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@media (max-width: 767px) {
    .aboutus-testimonial-title h2 {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
    .aboutus-testimonial-title h2 {
        font-size: 28px;
        line-height: 36px;
    }
}
.aboutus-testimonial-img {
    position: inherit;
    left: 0;
    top: 0;
    img {
        width: auto;
        height: auto;
        box-shadow: none;
        padding: 0;
        border-radius: 50%;
        max-width: 100%;
        animation: leftRight 1.2s infinite;
        animation-duration: 5s;
    }
}
.aboutus-testimonial-img::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 330px;
    width: 100%;
    content: "";
    z-index: -1;
    background-image: url(../../../img/07.png);
}
@media (max-width: 576px) {
    .aboutus-testimonial-img::before {
        height: 200px;
    }
}
@keyframes leftRight {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(30px, 0);
        transform: translate(30px, 0);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
.aboutus-testimonial-text {
    background: 0 0;
    padding: 0 !important;
    border-radius: 10px;
    p {
        color: #111121;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 25px;
        letter-spacing: 1px;
    }
}

@media (max-width: 767px) {
    .aboutus-testimonial-text p {
        font-size: 16px;
    }
}
@media (max-width: 576px) {
    .aboutus-testimonial-text p {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
    }
}


.flaticon-quotation::before {
    font-size: 70px !important;
}
@media (max-width: 767px) {
    .flaticon-quotation::before {
        font-size: 50px !important;
    }
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .owl-aboutus-image {
        min-height: auto !important;
    }
}

.testimonial-caption {
    h5 {
        background-color: $black-color;
        color: #fff;
        padding: 5px 8px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
      
        
    }
    h3{
        padding: 5px 8px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        color:#1c2045;
    }
}

@media (max-width: 576px) {
    .flaticon-quotation::before {
        font-size: 35px !important;
    }

    .owl-aboutus-image {
        min-height: auto !important;
    }
    .testimonial-caption {
        h5 {display: block !important;}
    }    
}

.aboutus-slider {
    
    .owl-stage {
        transition: all 0.25s ease 0s;
        padding-bottom: 30px;
        
    }
    .owl-aboutus-items {
        margin: 30px 10px;
       
        position: relative;
        transition: all 0.5s ease-in-out 0s;
        text-align: center;
        transition: all 0.5s ease-in-out 0s;
        background: white;
        border-radius: 10px;
        .owl-aboutus-image {
            min-height: 455px;
            align-items: center;
            display: flex;
        }
        img {
            width: 100% !important;
            height: auto;
            border-radius:10px 10px 0px 0px;
        }
        .owl-aboutus-description{
            border-radius:0px 0px 10px 10px;
        }
    }
    

    .owl-item.active.center {
       
        transition: all 0.5s ease-in-out 0s;
        transform: scale(1.1);
        z-index: 99;
        .owl-aboutus {            
            z-index: 99999;
         .owl-aboutus-description{
            box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
         }   
        }
        
    }
}
.owl-aboutus-description {
    background: #fff;
    padding: 20px;
    a {
        margin: 0 0 15px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        color: #1c2045;
        letter-spacing: 2px;
        transition: all 0.5s ease-in-out 0s;
        &:hover {
            color: $primary-color;
        }
    }
    h5 {
        margin: 0 0 15px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
        color: #1c2045;
        letter-spacing: 2px;
        transition: all 0.5s ease-in-out 0s;
        &:hover {
            color: $primary-color;
            cursor: pointer;
        }
    }
    span {
        font-weight: 500;
        display: block;
        line-height: 1.7px;
        color: #4f4e4e;
        letter-spacing: 1px;
        font-size: 15px;
    }
}
.owl-nav {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: 100%;
    text-align: center;
    bottom: -20px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    left: 50%;
    cursor: pointer;
    z-index: 999;
}
.owl-prev {
    background: none;
    border: 2px solid #efefef;
    border-radius: 30px;
    color: #141414;
    cursor: pointer;
    display: inline-block;
    font-size: 34px;
    line-height: 0;
    margin: 0 10px;
    padding: 10px 60px !important;
}

.aboutus-slider .owl-carousel .owl-nav button.owl-prev,
.aboutus-slider .owl-carousel .owl-nav button.owl-next {
   background: #86bc24 !important;
    border-radius: 100px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 20px !important;
    line-height: 0 !important;
    margin: 0 10px;
    padding: 0px !important;
    transition: all 0.5s ease-in-out 0s;
    height: 50px;
    width: 50px;
    &:focus {
        outline: none;
    }
    &:hover {
        background:#1c2045 !important;
        color: $white-color !important;
    }
}
@media (max-width: 992px) {
    .md-mt-5 {
        margin-top: 50px !important;
    }
    .testimonial.style-2{
        .testimonial-content{
            display:none;
        }        
    } 
    .testimonial-2 {       
        .controls {
            
            li {
                &:nth-child(1) {
                    left: 0;
                    bottom: 0%;
                }
                &:nth-child(2) {
                    left: 5%;
                    bottom: 55%;
                }
                &:nth-child(3) {
                    right: 5%;
                    bottom: 55%;
                }
                &:nth-child(4) {
                    right: 0;
                    bottom: 0%;
                }
            }
        }
    }  
}
@media (max-width: 576px) {
    .creative-team{
        padding: 20px 0px;
    }
    .aboutus-services{
        padding: 20px 0px;
    }
    .aboutusnews-client{
        padding-bottom: 20px;
    }
    .aboutus-success-stories{
        padding: 20px 0px;
    }
    .aboutuspage-conten{
        padding: 20px 0px;
    }
    .flaticon-quotation::before {
        font-size: 35px !important;
    }

    .owl-aboutus-image {
        min-height: auto !important;
    }
    .client-div {
        .clients-logo{
            height:auto !important;
            width: auto;
        }
    }
    .aboutuspage-header {
        .ani_inner_banner {
            left: 10px;
            bottom: 10px;
            transform: translate(-20%, 20%) scale(0.5);
        } 
    }
    .aboutuspage-header {
        .ani.ani-5{display:none;}
    }
    .aboutuspage-header {
        .ani_right{
            height: 100px;
            width: 100px;
        }
    }
    .testimonial.style-2{
        padding: 10px !important;
    }
    .carousel-inner{
        padding-bottom:20px !important;
    }
    .testimonial-2 {       
        .controls {  
            position:inherit !important;     
            ul{
                justify-content: space-around!important;                         
            li {
                position:inherit !important;
                margin-bottom: 20px;
                .names{
                    font-size:12px;
                } 
                .item_img{
                    height:100px !important;
                    width:100px !important;
                }  
            }
        }
        }
    }
    .testimonial.style-2{
        .names{
            font-size:12px;
        }
    }
    .aboutus-testimonial-img{
        img{
            width: 60%;
        }
    }  


}