.abouthome-main {
    padding: 80px 0px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    p {
        color: #1c2045 !important;
        line-height: 1.9;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 1px;
    }

    .animationnew-shap {
        position: absolute;
        bottom: -8%;
        right: -8%;
    }

   .animationnew-shap .shap-bg {
        text-align: center;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 515px;
        height: 515px;
        margin: auto;
        position: relative;
        position: relative;
        background-color: transparent;
        border-radius:20% 80% 30% 20% ;  
        animation: one-animated 10s infinite;
        overflow: hidden;
        transition: all 0.5s ease-out 0s;
        opacity: 1;
        border: 1px solid #86BC24;
    }
    .animationnew-shap .shap-bg-to {
        text-align: center;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 415px;
        height: 415px;
        margin: auto;
        position: absolute;
        background-color: transparent;
        border-radius:20% 80% 30% 20% ;  
        animation: two-animated 10s infinite;
        overflow: hidden;
        transition: all 0.5s ease-out 0s;
        opacity: 1;
        border: 1px solid #86BC24;
        top: 8%;
        left: 8%;
    }
    .animationnew-shap .shap-bg-three {
        text-align: center;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        background-color: transparent;
        border-radius:20% 80% 30% 20% ;  
        animation: two-animated 10s infinite;
        overflow: hidden;
        transition: all 0.5s ease-out 0s;
        opacity: 1;
        border: 1px solid #86BC24;
        top: 25%;
        left: 25%;
    }
    @keyframes one-animated {
        0% { border-radius: 20% 80% 30% 20%;}
        25% { border-radius: 58% 42% 75% 25%;}
        50% { border-radius: 50% 50% 33% 67%;}
        75% { border-radius: 33% 67% 58% 42%;}
        }

        @keyframes two-animated {
            0% { border-radius: 20% 80% 30% 20%;}
            25% { border-radius: 58% 42% 75% 25%;}
            50% { border-radius: 50% 50% 33% 67%;}
            75% { border-radius: 33% 67% 58% 42%;}
            }  
            
            @keyframes three-animated {
                0% { border-radius: 20% 80% 30% 20%;}
                25% { border-radius: 58% 42% 75% 25%;}
                50% { border-radius: 50% 50% 33% 67%;}
                75% { border-radius: 33% 67% 58% 42%;}
                } 
                
                
                .iq-fadebounce {animation-name: fadebounce; -webkit-animation-name: fadebounce;-moz-animation-name: fadebounce;-ms-animation-name: fadebounce;-o-animation-name: fadebounce;animation-name: fadebounce;-webkit-animation-duration: 3s;-moz-animation-duration: 3s;-ms-animation-duration: 3s;-o-animation-duration: 3s;animation-duration: 3s;-webkit-animation-iteration-count: infinite;-moz-animation-iteration-count: infinite;-ms-animation-iteration-count: infinite;-o-animation-iteration-count: infinite;animation-iteration-count: infinite;}
                @-moz-keyframes fadebounce {
                  0% { -moz-transform: translateY(0); transform: translateY(0); opacity: 1 }
                  50% { -moz-transform: translateY(20px); transform: translateY(20px); opacity: 1 }
                  100% { -moz-transform: translateY(0); transform: translateY(0); opacity: 1 }
                }
                @-webkit-keyframes fadebounce {
                  0% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1 }
                  50% { -webkit-transform: translateY(20px); transform: translateY(20px); opacity: 1 }
                  100% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1 }
                }
                @-o-keyframes fadebounce {
                  0% { -o-transform: translateY(0); transform: translateY(0); opacity: 1 }
                  50% { -o-transform: translateY(20px); transform: translateY(20px); opacity: 1 }
                  100% { -o-transform: translateY(0); transform: translateY(0); opacity: 1 }
                }
                @-ms-keyframes fadebounce {
                  0% { -ms-transform: translateY(0); transform: translateY(0); opacity: 1 }
                  50% { -ms-transform: translateY(20px); transform: translateY(20px); opacity: 1 }
                  100% { -ms-transform: translateY(0); transform: translateY(0); opacity: 1 }
                }
                @keyframes fadebounce {
                  0% { transform: translateY(0); opacity: 1 }
                  50% { transform: translateY(20px); opacity: 1 }
                  100% { transform: translateY(0); opacity: 1 }
                }
}
.about-bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
}
.img-rotateme {
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 40s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-name: rotateme;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.abouthome-title_n {
    margin-bottom: 50px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #fff;
        margin-bottom: 20px;
        text-transform: capitalize;
    }
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 50px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
       span{
          color: #86BC24; 
       } 
    }
    p {
        color: #1c2045;
        line-height: 1.9;
        letter-spacing: 1px;
    }

    ul li {
        color: #1c2045;
        line-height: 1.9;
        letter-spacing: 1px;
    }
}

.abouthome-title {
    margin-bottom: 50px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #fff;
        margin-bottom: 20px;
        text-transform: capitalize;
    }
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 50px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: capitalize;
    }
    p {
        color: #1c2045;
        line-height: 1.9;
        letter-spacing: 1px;
    }

    ul li {
        color: #1c2045;
        line-height: 1.9;
        letter-spacing: 1px;
    }
}

@media (max-width: 1200px) {
    .abouthome-title {
        h6 {
            font-size: 17px;
            line-height: 22px;
        }
    }
}
@media (max-width: 992px) {
    .abouthome-title {
        h6 {
            font-size: 17px;
            line-height: 26px;
        }
    }
}
@media (max-width: 767px) {
    .abouthome-title {
        h2 {
            font-size: 34px;
            line-height: 40px;
        }
    }
}
@media (max-width: 576px) {
    .abouthome-title {
        h2 {
            font-size: 28px;
            line-height: 36px;
        }
    }
    .abouthome-main {
        .iq-fadebounce {
         display: none;   
        }        
    }    
}
.abouthome-title h2::before {
    position: absolute;
    left: 0;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 7s infinite linear;
    -webkit-animation: rotateline 70s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(
        to right,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
}
.abouthome-title_n h2::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 7s infinite linear;
    -webkit-animation: rotateline 7s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
.counter-bg {
    padding: 80px 0px;
    background-color: #f3f6ff;
    position: relative;
}
.counter-style {
    text-align: center;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: relative;
    span {
        margin: 25px 0 15px;
        display: block;
        color: #111121;
        font-size: 50px;
        font-weight: 400;
        line-height: 50px;
    }
    h5 {
        color:$primary-color;
        display: block;
        font-weight: 500;
        font-size: 20px;
        position: relative;
        margin: 10px 0 0;
    }
    img{
        height:80px;
    }
}
 
.owl-carousel {
    .aboutushome-owl {
        padding: 60px 30px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: relative;
        transition: all .5s ease-in-out 0s;
        height: 100%;
        min-height:590px;
        &:hover{
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
        }
        .aboutushome-owl-icon {
            display: inline-block;
            .aboutushome-owl-img {
                max-width: 100%;
                width: auto;
            }
            .owl-item img {
                width: auto !important;
                max-width: 100%;
                display: inline-block;
             
            }
            img{
                width: 80px !important;
            }
        }
    }
}
.aboutushome-owl-heading {
    h5 {
        margin: 30px 0 20px;
        text-transform: capitalize;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color:$primary-color;
        letter-spacing: 2px;
    }
}

.owl-carousel .owl-item img {
    display: inline-block !important;
   
}
.aboutushome-owl-details {
    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.9;
        color: #4f4e4e;
        letter-spacing: 1px;
        font-weight: 400;
    }
}
.owl-theme .owl-nav {
    display: none;
}
.owl-theme .owl-dots {
    display: none;
}
.aboutushome-text2 {
    padding-top: 80px;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .owl-carousel{
        .aboutushome-owl{
            min-height:670px;
        }        
    } 

}

@media (max-width: 576px) {
    .xs-mt-5 {
        margin-top: 50px !important;
    }
    .abouthome-main{
        padding:20px 0px;
    }   
}
