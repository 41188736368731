.testimonial-bg {
    padding: 80px 0px;
    background-image: url(../../../img/08.webp);
    background-size: cover;
    // background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center !important;
    background-color: #ffffff;
    color: #1c2045;
    overflow: hidden;
    position: relative;
    .owl-carousel .owl-stage-outer {
        padding-bottom: 60px;
    }
.iq-waves {
        position: absolute;
        width: 24rem;
        height: 24rem;
        left: -15rem;
        top: -15rem;
        z-index: 2;
        opacity:0.1;

        .waves {
            position: absolute;
            width: 35rem;         
            height: 35rem;
            background: #86BC24;
            opacity: 0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            border-radius: 320px;
            background-clip: padding-box;
            -webkit-animation: waves 5s ease-in-out infinite;
            animation: waves 5s ease-in-out infinite;
        }
        .wave-1 {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }
        .wave-2 {
            -webkit-animation-delay: 1s;
            animation-delay: 2s;
        }
        .wave-3 {
            -webkit-animation-delay: 2s;
            animation-delay: 3s;
        }
        @-webkit-keyframes waves {
            0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
            50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
            100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
          }
          @keyframes waves {
            0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
            50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
            100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
          }
    }

}

.testimonial-bg .owl-carousel button.owl-dot span {
    background: #141414;
    border-radius: 2px;
    display: inline-block;
    width: 10px;
    height: 8px;
    margin: 0px 3px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    cursor: pointer;
   
}
.testimonial-bg .owl-carousel button.owl-dot.active span {
    background: #86bc24;
}

button:focus{
    outline: medium none !important;
}
.testimonial-bg .owl-carousel .owl-dots .owl-dot.active span,
.testimonial-bg .owl-carousel .owl-dots .owl-dot:hover span {
    width: 22px !important;
}
.testimonial-text {
    align-items: center;
}
.testimonial-title {
    position: relative;
    color: #1c2045;
    padding-bottom: 20px;
    margin-bottom: 25px;
    font-size: 38px;
    text-align: initial;
    font-style: normal;
    line-height: 46px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #1c2045;
    text-transform: capitalize;
    span{
        color:#86bc24;
    }
}







//Start new scss

.testimonial-style.owl-carousel {
    display: block;
	.testimonial-info {
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			font-size: 84px;
			width: 205px;
			background: url(../../../img/qu.png)no-repeat;        
			height: 140px;
			opacity: 1;
			top: 111%;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
	}
    .iq-objects-03{
        position:absolute;
        right:0%;
        top:-2%;
    }
	.owl-item {
		.testimonial-info {
            .testimonial-info-img{
            width: 100px;
				height: 100px;
				display: inline-block;
				margin-bottom: 45px;
				box-shadow: 0px 8px 38px 0px rgba(0, 0, 0, 0.1);
				border: 2px solid #ffffff;
                border-radius:100px;
                line-height:100px;
                background-color:#fff;

			img {
				border-radius:0px;
                width: auto;
			}
        }
		}
	}
	.testimonial-name {
		text-align: center;
		position: relative;
		z-index: 1;
		display: block;
		.content {
			display: inline-block;
            color:#1c2045;
            font-weight: 500;
            font-size:20px;
		}
	}
	.iq-testimonial {
		p {
			margin-bottom: 0;
			z-index: 1;
			position: relative;
            color:#4f4e4e;
            font-weight:400;
		}
		padding: 45px 30px;
		margin: 52px 30px 70px 30px;
		box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
		background: #ffffff;
		border-radius: 4px;
		display: block;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			bottom: -12px;
			width: 90%;
			z-index: -1;
			border-radius: 4px;
			height: 100%;
			background: #aabf82;
			box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
            left:0;
            right: 0;
            margin: 0 auto;
		}
		&:after {
			content: "";
			position: absolute;
			bottom: -24px;
			width: 82%;
			left: 0;
			margin: 0 auto;
			right: 0;
			z-index: -2;
			border-radius: 4px;
			height: 100%;
			background: #86bc244a;
			box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
            left:0;
            right: 0;
		}
	}
	.owl-dots {
		position: absolute;
		bottom: 0px;
	}
}
.testimonial-style {
	.testimonial-info {
		position: relative;
		text-align: center;
		display: block;
		margin-bottom: 25px;
	}
}
.test-shadow {
	.testimonial-style.owl-carousel {
		.iq-testimonial {
			margin: 15px 36px 66px 36px;
		}
		.owl-dots {
			position: absolute;
			top: 68%;
			left: -106%;
			width: auto;
		}
	}
}













@media (max-width: 767px) {
    .testimonial-title {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
    .testimonial-title {
        font-size: 28px;
        line-height: 36px;
    }
}
.testimonial-title:before {
    position: absolute;
    left: 0;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotate 80s infinite linear;
    -webkit-animation: rotate 80s infinite linear;
    content: "";
    border-radius: 5px;
    background: #ff7810;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
.testimonial-p {
    font-size: 16px;
    color: #4f4e4e;
    letter-spacing: 1px;
    text-align: initial;
}
.testimonail-main {
    background-color: $primary-color;
    padding-left: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    text-align: center;
    margin: 50px;
    display: inline-block;
    p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
    }
    h5 {
        background: rgba(0, 0, 0, 0.06);
        color: #fff;
        padding: 5px 8px;
        letter-spacing: 2px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        font-style: normal;
        line-height: 26px;
    }
    label {
        margin-bottom: 0;
        font-weight: 500;
        color: #fff;
        display: block;
        font-size: 14px;
    }
}

.carousel-indicators .active {
    background-color: $black-color !important;
    width: 20px !important;
    border-radius: 2px !important;
}
.carousel-indicators {
    bottom: -5% !important;
}
.carousel-indicators li {
    background-color: $black-color !important;
    width: 9px !important;
    height: 8px !important;
    border-radius: 2px !important;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}
.carousel-indicators li:hover {
    background-color: $black-color !important;
    width: 20px !important;
    border-radius: 2px !important;
}
.testimonail-main::before {
    width: 0;
    height: 0;
    border-top: 100px solid #ff7810;
    border-right: 100px solid transparent;
    content: "";
    position: absolute;
    bottom: -50px;
    left: 50px;
    border-top-color: #3414bc;
}
.testimonail-main:before {
    border-top-color: $primary-color;
}
.carousel-inner {
    padding-bottom: 60px;
}

@media (max-width: 576px) {
    .testimonail-main {
        margin: 50px 0;
        padding-bottom: 20px;
        padding-left: 20px;
    }
}
@media (max-width: 576px) {
    .testimonail-main::before {
        left: 0 !important;
    }
}
.testimonial-content {
    background-color: $black-color;
    padding: 50px;
    border-radius: 10px;
}
@media (max-width: 992px) {
    .testimonial-content h5 {
        font-size: 18px;
        line-height: 28px;
    }
}
@media (max-width: 767px) {
    .testimonial-content p {
        font-size: 16px;
    }
}
@media (max-width: 576px) {
    .testimonial-style.owl-carousel{
        padding:20px 0px;
    }
    .testimonial-content {
        padding: 40px 20px 20px;

        p {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
    .testimonial-style {
        .iq-testimonial{
            padding:15px !important;
          margin:15px !important;
        }
    }
}


