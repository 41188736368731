	/*
  	Flaticon icon font: Flaticon
  	Creation date: 08/06/2020 13:22
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        /* font-size: 20px; */
font-style: normal;
}

.flaticon-quotation:before { content: "\f100"; }
.flaticon-quote:before { content: "\f101"; }
.flaticon-double-check:before { content: "\f102"; }
.flaticon-upload:before { content: "\f103"; }
.flaticon-location:before { content: "\f104"; }
.flaticon-phone-ringing:before { content: "\f105"; }
.flaticon-email:before { content: "\f106"; }