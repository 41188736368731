.technologiespage-bg-white {
  background-color: #ffffff;
  overflow: hidden;
}
.technologiespage-header {
  background: #f8f8fb url(../../../img/08.webp);
  display: block;
  position: relative;
  padding: 200px 0;
  overflow: hidden;
  text-align: center;



  svg {
    #followPath {
        fill: none;
        stroke: #86bc24;
        stroke-width: 8;
        stroke-dasharray: 2870px;
        stroke-dashoffset: 2870px;
        -webkit-animation: drawPath 6s linear infinite;
        animation: drawPath 6s linear infinite;
    }
     #dashedPath {
        fill: none;
        stroke: #fff;
        stroke-width: 14;
        stroke-dasharray: 50px;
    } 
    #airplain {
        fill: #86bc24;
        -webkit-transform: translate(-2px, -45px);
        transform: translate(-2px, -45px);
    }
}
.ani_right {
    height: 320px;
    width: 320px;
    right: 0;
    top: 0;
    position:absolute;
}
@-webkit-keyframes drawPath {
    0% { opacity: 1; stroke-dashoffset: 2870px; }
    53% { opacity: 1; stroke-dashoffset: 0; }
    78% { opacity: 0; }
    100% { opacity: 0; stroke-dashoffset: 0; }
    }
    @keyframes drawPath {
    0% { opacity: 1; stroke-dashoffset: 2870px; }
    53% { opacity: 1; stroke-dashoffset: 0; }
    78% { opacity: 0; }
    100% { opacity: 0; stroke-dashoffset: 0; }
    }

    .ani.ani-5 {
        left: 5%;
        top: 10%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
   
    .moving-square-frame {
        position: absolute;
        height: 40px;
        width: 40px;
        top: 10px;
        left: 10px;
        opacity: .4;
        border: 1px solid #1d1d1f;
    }
    .ani-moving-square {
        border: 1px solid #86bc24;
        height: 40px;
        width: 40px;
        opacity:1;
        -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
    }
    @-webkit-keyframes ani-moving-square {
        0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        }
        @keyframes ani-moving-square {
        0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        }
}


.ani_inner_banner{
left: 100px;
bottom: 100px;
-webkit-transform: translate(-50%, 50%) scale(0.8);
transform: translate(-50%, 50%) scale(0.8);
position: absolute;

.ani-cube {
    -webkit-perspective: 20000px;
    perspective: 20000px;
    height: 100px;
    width: 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(
-30deg) rotateY(
40deg) rotateZ(
-360deg);
    transform: rotateX(
-30deg) rotateY(
40deg) rotateZ(
-360deg);
    -webkit-animation: ani-cube-1 20s linear infinite;
    animation: ani-cube-1 20s linear infinite;
}

.ani-cube.ani-cube-3 {
    -webkit-transform: scale(0.6) rotateX(
-63deg) rotateY(
13deg) rotateZ(
-47deg);
    transform: scale(0.6) rotateX(
-63deg) rotateY(
13deg) rotateZ(
-47deg);
    -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
    animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;


    .cube-face.face_back {
        background-color: #86bc24;
        -webkit-transform: translate3d(0px, 0px, -50px);
        transform: translate3d(0px, 0px, -50px);
        z-index: 1;
    }
    .cube-face {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 100px;
        height: 100px;
    }  

   
   
    .cube-face.face_front {
        background-color: #86bc24;
        -webkit-transform: translate3d(0px, 0px, 50px);
        transform: translate3d(0px, 0px, 50px);
        z-index: 10;
    }
    .cube-face.face_right {
        background-color: #86bc24;
        -webkit-transform: rotateX(
    0deg) rotateY(
    90deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        transform: rotateX(
    0deg) rotateY(
    90deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        z-index: 8;
    }
    .cube-face.face_left {
        background-color: #86bc24;
        -webkit-transform: rotateX(
    0deg) rotateY(
    -90deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        transform: rotateX(
    0deg) rotateY(
    -90deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        z-index: 9;
    }
    .cube-face.face_top {
        background-color: #86bc24;
        -webkit-transform: rotateX(
    90deg) rotateY(
    0deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        transform: rotateX(
    90deg) rotateY(
    0deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        z-index: 11;
    }
    .cube-face.face_bottom {
        background-color: #86bc24;
        -webkit-transform: rotateX(
    -90deg) rotateY(
    0deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        transform: rotateX(
    -90deg) rotateY(
    0deg) rotateZ(
    0deg) translate3d(0px, 0px, 50px);
        z-index: 5;
    }
    
    .cube-face.face_front {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }
    .cube-face.face_right {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }
    .cube-face.face_left {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }
    .cube-face.face_top {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }
    .cube-face.face_bottom {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }
    .cube-face.face_back {
        border: 2px solid #86bc24;
        border-radius: 2px;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
    }             

    @-webkit-keyframes ani-cube-3 {
        0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
        5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
        10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
        100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
        }
        @keyframes ani-cube-3 {
        0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
        5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
        10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
        100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
        }
        
}

}        





}
@media (max-width: 992px) {
  .technologiespage-header {
    padding: 160px 0;
  }
}
@media (max-width: 576px) {
  .technologiespage-header {
    padding: 160px 0 110px;
  }
}
@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
.technologiespage-title {
  font-size: 50px;
  color: #1c2045;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
}

.technologiespage-title::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  position: absolute;
  width: 100px;
  margin: 0 auto;
  bottom: 0;
  height: 3px;
  animation: rotateline 07s infinite linear;
  -webkit-animation: rotateline 07s infinite linear;
  content: "";
  border-radius: 5px;
  background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
.technologiespage-title-pattern-img {
  vertical-align: middle;
}
.technologiespage-title-pattern {
  bottom: 0;
  position: absolute;
}
.technologiespage-content {
  padding: 80px 0px;
  background: #ffffff url(../../../img/23.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-top:1px solid #86bc24;
}
.technologiespage-content {
  .servicepage-section1-card {
    .technologies-icons {
      background: #fff;
      display: inline-block;
      font-size: 30px;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease-in-out 0s;
      -webkit-transition: all 0.5s ease-in-out 0s;

      svg {
        width: 60px;
        height: 60px;
      }
    }
    .bg-and{
        border: 2px solid #78c258;
        svg {  
        fill:#78C257 ; 
        } 
    } 
    .bg-react{
        border: 2px solid #61dbfb;
        svg {  
        fill:#61DBFB ; 
        } 
    } 
    .bg-flutt{
        border: 2px solid #42a5f5;
        svg {  
        fill:#42A5F5; 
        } 
    } 
    .bg-ios{
        border: 2px solid #444444;
        svg {  
        fill:#444444; 
        } 
    }
    .bg-ionic{
        border: 2px solid #498aff;
        svg {  
        fill: #498aff; 
        } 
    }
    .bg-magento{
        border: 2px solid #ffff;
        background: #f37b21 !important; 
    }

    .bg-shopify{
        border: 2px solid #ffff;
        background: #96bf48 !important; 
    }
    .bg-woocomerce{
        border: 2px solid #96588a;
        svg {
            fill: #96588a;
        }
    }
    .bg-opencart{
        border: 2px solid #ffff;
        background: #76c1eb !important;
    }
    .bg-bigcommerce {
        border: 2px solid #ffff;
        background: black !important;
        svg {
            fill: #ffff;
        }
    }
    .bg-lms {
        border: 2px solid #439fcd;
        svg {
            fill: #439fcd;
        }
    }
    .bg-crm {
        border: 2px solid #439fcd;
        svg {
            fill: #439fcd;
        }
    }
    .bg-erp {
        border: 2px solid #006627;
        svg {
            fill: #006627;
        }
    }
    .bg-sis {
        border: 2px solid #1C335A;
        svg {
            fill: #1C335A;
        }
    }
    .bg-rubyrails {
        border: 2px solid #ffff;
        background: #cc0000 !important;
        svg {
            fill: #ffff;
        }
    }
    .bg-laravel {
        border: 2px solid #ffff;
        background: #f25247 !important;
    }
    .bg-salesforce {
        border: 2px solid #ffff;
        background: #45a1e0 !important;
        svg {
            fill: #ffff;
        }
    }
    .bg-cordi {
        border: 2px solid #DD4814;
        svg {
            fill: #DD4814;
        }
    }
    .bg-symphony {
        border: 2px solid #455a64;
        svg {
            fill: #213c5e;
        }
    }
  }
  .mobile_app {
    padding: 30px;
    background: linear-gradient(100deg, #1d1d1f 40%, #ffffff 0%);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);

    .servicepage-section1-card {
      box-shadow: none;
      background: transparent;
      padding: 10px;
    }
  }
  .technologies-title {
    h2 {
      color: #ffffff;
      padding-bottom: 20px;
      margin-bottom: 5px;
      font-size: 25px;
      font-style: normal;
      position: relative;
      line-height: 35px;
      font-weight: 600;
      letter-spacing: 2px;
      margin-top: 95px;
    }
  }
  .mobile_app {
    .technologies-title {
      p {
        width: 78%;
      }
    }
  }

  .ecommerce_bg {
    background: linear-gradient(-100deg, #1d1d1f 40%, #ffffff 0%);

    h2 {
      text-align: right;
    }
    .technologies-title {
      p {
        width: 100%;
        padding-left: 123px;
        text-align: right;
      }
    }
  }
}

.technologiespage-title-section {
  margin: 50px 0px;
  h6 {
    background-color: $black-color;
    padding: 3px 5px;
    position: relative;
    display: inline-block;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  h2 {
    color: #1c2045;
    padding-bottom: 20px;
    margin-bottom: 25px;
    font-size: 38px;
    font-style: normal;
    position: relative;
    line-height: 46px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 0px;
    span{
      color:#86BC24;
    }
  }
  p {
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 1.9;
    letter-spacing: 1px;
    margin-top: 0px;
    color: #4f4e4e;
    font-weight: 500;
  }
}
.technologiespage-title-section h2::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  position: absolute;
  width: 100px;
  margin: 0 auto;
  bottom: 0;
  height: 3px;
  animation: rotate 80s infinite linear;
  -webkit-animation: rotate 80s infinite linear;
  content: "";
  border-radius: 5px;
  background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
.technologiespage-item {
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out 0s;
  img {
    border-radius: 0.2857rem 0.2857rem 0rem 0rem;
    height: 349.48px;
  }
}
.technologiespage-item:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}
.technologiespage-item-details {
  background: #1d1d1f;
  padding: 10px 20px;
  bottom: 0;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  border-top: 5px solid #86bc24;
  h5 {
    margin: 0 0 10px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    line-height: 26px;
    color: #fff;
    letter-spacing: 2px;

    a {
      color: #fff;
      font-weight: 500;
      transition: all 0.5s ease-in-out 0s;
    }
  }
  span {
    font-weight: 500;
    display: block;
    color: #dadada;
    font-size: 15px;
  }
}
.technologiespage-item-details h5:hover {
  color: $primary-color;
  cursor: pointer;
}
@media (max-width: 768px) {

  .technologiespage-title {
    font-size: 30px;
    line-height: 30px;
  }
  .technologiespage-content {
      padding: 0;
    .mobile_app {
      background: linear-gradient(180deg, #1d1d1f 15%, #ffffff 0%);
    }
    .ecommerce_bg {
      background: linear-gradient(0deg, #1d1d1f 22%, #ffffff 0%);

      .technologies-title {
        margin-top: 5rem;
        h2 {
            text-align: left;
        }
        p {
            text-align: left;
        }
      }
    }
    .technologies-title {
      margin-bottom: 5rem;
      h2 {
        font-size: 25px;
        margin-top: 0px;
      }
    }
  }
}

@media (max-width: 576px) {
  .technologiespage-title-section{
    margin: 0px;
    h2{
      font-size: 28px;
    }
 
  } 

  .technologiespage-content {
    padding-bottom:15px;
    .mobile_app {
      background: linear-gradient(180deg, #1d1d1f 22%, #ffffff 0%);
      p {
        width: 100% !important;
      }
    }
    .ecommerce_bg {
      background: linear-gradient(0deg, #1d1d1f 30%, #ffffff 0%);

      .technologies-title {
        margin-top: 5rem;
        p {
          padding: 0px !important;
          width: 100% !important;
         
        }
      }
    
       
    }
    .technologies-title {
      margin-bottom: 5rem;
      h2 {
        font-size: 22px;
        margin-top: 0px;
        line-height: 35px;
      }
      p {
        font-size:14px !important;
      }

    }
  }
  .technologiespage-header {
    .ani_inner_banner {
        left: 10px;
        bottom: 10px;
        transform: translate(-20%, 20%) scale(0.5);
    } 
}
.technologiespage-header {
    .ani.ani-5{display:none;}
}
.technologiespage-header {
    .ani_right{
        height: 100px;
        width: 100px;
    }
}
}
