.faq-content {
    padding: 80px 0;
    background-color: #ffffff;
    .faq-title {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 0px;
        letter-spacing: 2px;
        margin-bottom:4rem;
    }
    .faq-title::before {
        position: absolute;
        left: 0;
        width: 100px;
        margin: 0 auto;
        bottom: 0;
        height: 3px;
        animation: rotateline 7s infinite linear;
        -webkit-animation: rotateline 7s infinite linear;
        content: "";
        border-radius: 5px;
        background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
    }
    .faq-subtext {
        line-height: 1.9;
        letter-spacing: 1px;
        color: #4f4e4e;
        font-size: 15px;
        margin-bottom: 1rem;
        font-weight: normal;
    }
    .faq-text {
    color: #1d1d1f;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 2px;
    margin: 0px 0 !important;
    text-transform: uppercase;
    padding:3px 5px !important;
    }

    .MuiPaper-elevation1 {
        box-shadow: 4.871px 34.659px 30px 5px rgba(0, 0, 0, 0.06);
        border-bottom: 2px solid #86bc24;
        margin-bottom: 2rem;
    }
    .MuiAccordionSummary-root{
        padding:3px 5px !important;
    }
    .MuiPaper-elevation1:hover {    
    .faq-text {
        color: #ffffff;
    }
    .MuiSvgIcon-root{
        color: #ffffff;
    }
    .MuiAccordionSummary-root{
        background: #86bc24;  
    }

    }

    .MuiAccordion-root:before{
        display: none;
    }
    .MuiSvgIcon-root{
        color: #86bc24;
    }

    @media screen and(max-width:1200px) {
        .faq-text {
            font-size: 20px;
            line-height: 30px;
        }
        .faq-title {
            font-size: 32px;
            line-height: 40px;
        }
    }
    @media screen and(max-width:992px) {
        .faq-text {
            font-size: 20px;
            line-height: 26px;
        }
        .faq-title {
            font-size: 30px;
            line-height: 40px;
        }
    }
    @media screen and(max-width:576px) {
        .faq-text {
            font-size: 14px;
            line-height: 22px;
        }
        .faq-title {
            font-size: 26px;
            line-height: 34px;
        }
     
            .faq-subtext{
                font-size:14px !important;
            }
       
    }
}
