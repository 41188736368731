.video-main {
    background-color: #0e0c0e;
    overflow: hidden;
    padding: 80px 0px;
    display: block;
    text-align: center;
    position: relative;
    .round-p-animation{
        left:-100px;
    }
    .ani.ani-5 {
        right: 5%;
        top: 80%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
   
    .moving-square-frame {
        position: absolute;
        height: 80px;
        width: 80px;
        top: 10px;
        left: 10px;
        opacity: .2;
        border: 1px solid #ccc;
    }
    .ani-moving-square {
        border: 1px solid #86bc24;
        height: 80px;
        width: 80px;
        opacity:0.4;
        -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
    }
    @-webkit-keyframes ani-moving-square {
        0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        }
        @keyframes ani-moving-square {
        0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
        72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
        85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
        97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
        }
}
    img{
       border-radius:0px; 
    }
    .video-title {
        margin-bottom: 50px;
        h2 {
            position: relative;
            color: #fff;
            padding-bottom: 20px;
            margin-bottom: 25px;
            font-size: 38px;
            font-style: normal;
            line-height: 46px;
            font-weight: 600;
            text-transform: capitalize;
            margin-top: 0px;
            letter-spacing: 2px;
            z-index:9;
            span{
                color: #86bc24;
            }
        }
        h2::before {
            position: absolute;
            width: 100px;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            margin: 0 auto;
            left: 50%;
            bottom: 0;
            height: 3px;
            animation: rotateline 8s infinite linear;
            -webkit-animation: rotateline 8s infinite linear;
            content: "";
            border-radius: 5px;
            background: rgb(255, 120, 16);
            background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
        }
    }
    .video-player{
        width: 100%;
        margin-top:-120px;
    }
}
@media (max-width: 767px) {
    .video-main {
        .video-title {
            h2 {
                font-size: 34px;
                line-height: 40px;
            }
        }
    }
}

@media (max-width: 576px) {
    .video-main {
        .video-title {
            h2 {
                font-size: 28px;
                line-height: 36px;
            }
        }
        .video-player{

            margin-top: -50px;
        }
        .ani.ani-5 {
            top: 90%;
            .moving-square-frame {
                height: 40px;
                width: 40px
            }
            .ani-moving-square{
                height: 40px;
                width: 40px
            }
        }  
        
    }
}


@keyframes rotateline {
    from {
        background-position: 20px 20px, 30px 80px, 0 0;
    }
    to {
        background-position: 300px -90px, 30px 20px, 100% 0;
    }
}
