body{
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    line-height: 1.7;
    color: #4f4e4e;
    letter-spacing: 1px;
}
::selection {
    background: $primary-color;
    color: $white-color;
}