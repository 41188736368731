.workhere-main {
    padding: 80px 0px;
    // background-image: url(../../../img/workhere-bg.png);
    background-image: url(../../../img/portfolio.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: hidden;
    animation: animatedBackground 20s linear infinite;
    -ms-animation: animatedBackground 20s linear infinite;
    -moz-animation: animatedBackground 20s linear infinite;
    -webkit-animation: animatedBackground 20s linear infinite;
    
    .owl-dots {
        display: block;
        margin-top: 3rem !important;
    }

    .owl-dot.active {
        span{
            background: #86bc23 !important ;
        }
    }

    h2:hover {
        color: #86BC24;
        cursor: pointer;
    }

    .react-tabs{
        padding-top: 35px;
    }

    .single_screen_slide {
        background: rgba(0, 0, 0, 0) url(../../../img/desktop_001.webp) no-repeat scroll center center;
        padding: 5% 4% 13% 4%;
        background-size: contain;
        height: 455px;

        .owl-carousel{
            height: 80%;
            overflow: hidden;  
            background: transparent;
        }
        .owl-stage-outer {
            padding: 0;
        }   
    }

    .mobile_app_layout {
        padding: 5.4% 33% 5% 33% !important;
        background:none; 
        .owl-carousel{
            height: 100% !important;
            border-radius: 15px;
        }
        img{
            border-radius: 20px;
        }
    }
    .mobile_app_layout:after {
        background-image: url('../../../phone001.png') !important;
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        right: 0;
        margin: auto;
        text-align: center;
        background-position: center;
        z-index: 9;
    }

    .react-tabs__tab-list {
        border-bottom: none !important;
        margin: 0 0 6% !important;
        padding: 0;
        left: auto;
        margin-top: -50px !important;
        right: 0%;
        top: 50%;
        margin-left: 0% !important;
        color: #1c2045;
        display: flex;
        position: relative;
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important;

       li{
           margin:0px;
           padding:10px 25px !important;
           border:0px !important;
           transition-duration:0.5s;
       } 
       .react-tabs__tab--selected {
        background-color: #86bc23 !important;      
        
    }
    .react-tabs__tab{
        border-radius: 50px 0px 0px 50px !important;  
    }
    .react-tabs__tab:after{
        display: none;  
    }
    .mobile-tab-btn {
        border-radius:0px 50px 50px 0px !important;   
    }

    }


    .home_text_slide {
        .owl-nav {
            left: 10%;
                span {
                    color: #fff;
                    background: linear-gradient(45deg, #baf353 0%, #86bc23 90%);
                    box-shadow: 0 5px 20px #4242423d;
                    font-size: 30px;
                    border-radius: 30px;
                    width: 40px;
                    height: 40px;
                    display: block;
                    line-height: 40px;
                    transition-duration:0.8s;
                    transition: all 0.5s ease-in-out 0s;
                    -webkit-transition: all 0.5s ease-in-out 0s;
                }
                span:hover {                  
                    background: linear-gradient(45deg, #86bc23 0%, #baf353 90%);
                    transition-duration:0.8s;
                    transition: all 0.5s ease-in-out 0s;
                    -webkit-transition: all 0.5s ease-in-out 0s; 
                 
                }
                
        }

        h1 {
            color: #fff;
        }
        p {
            color: #fff;
            padding: 0 5px;
        }

        
    }


    
}




.workhere-title {
    margin-bottom: 50px;
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 39px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        text-transform: capitalize;
        @media screen and(max-width:767px) {
            font-size: 34px;
            line-height: 40px;
        }
        @media screen and(max-width:576px) {
            font-size: 28px;
            line-height: 36px;
        }
    }
}
.react-tabs__tab-list {
    border-bottom: none !important;
    margin: 0 0 6% !important;
    padding: 0;
    left: auto;
    margin-top: -100px !important;
    right: 10%;
    top: 50%;
    margin-left: 44% !important;
    color: #1c2045;
}
@media (max-width: 768px) {
    .react-tabs__tab-list {
        margin-top: -20px !important;
        margin-left: 15% !important;
    }
    .react-tabs__tab {
        font-size: inherit !important;
    }
}
.react-tabs__tab--selected {
    background-color: $black-color !important;
    color: #fff !important ;
    // border: none !important;
    border-radius: 0 !important;
}
.react-tabs__tab {
    background-color: whitesmoke;
    margin-left: 20px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: #111121;
    padding: 1px 6px !important;
    letter-spacing: normal;
}
.react-tabs__tab-panel--selected {
    margin-left: 2% !important;
}
.react-tabs__tab:hover {
    background-color: $black-color !important;
    color: #fff !important;
}
.tabpanel-grid {
    position: relative;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
}
.tabpanel-grid-item {
    padding: 0px;
    width: 33.33333%;
    display: inline-block;
    @media screen and(max-width:992px) {
        width: 50%;
    }
    @media screen and(max-width:767px) {
        width: 100%;
    }
}
.tabpanel-img {
    width: 100%;
    display: inline-block;
    border-radius: 0;
}
.tabpanel-img:hover {
    background-color: $black-color;
}
.tabpnael-imgtext {
    position: relative;
    overflow: hidden;
}
.tabpnael-imgtext::before {
    display: block;
    background: rgba(0, 0, 0, 0.95);
    content: "";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    z-index: 1;
}
.tabpanel-text {
    padding: 0 3rem;
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: absolute;
    left: 0;
    top: 3.6rem;
    // width: 100%;
    // height: 100%;
    // background-color: rgb(49, 49, 49);
    // z-index: 2;
    // color: white;

    span {
        color: $primary-color;
        font-size: 15px;
        text-transform: uppercase;
    }
    h5 {
        font-size: 24px;
        color: #ffffff;
    }
}
// .tabpanel-text:hover{
//     opacity: 1;
//     visibility: visible;
//     -webkit-transform: translate3d(0,0,0);
//     -ms-transform: translate3d(0,0,0);
//     transform: translate3d(0,0,0);

// }

.tabpnael-imgtext:hover {
    .tabpanel-text {
        opacity: 1;
        // background-color: rgba(0, 0, 0, 0.95);
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 1;
    }
    .popup-icon {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 1;
    }
    .learn-more {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        &:hover{
            background-color: $black-color;
            color: #ffffff;
        }
    }
    &::before {
        opacity: 1;
        visibility: visible;
    }
}
.popup-icon {
    display: block;
    font-size: 18px;
    // color: #111121;
    color: #111121 !important;
    background: #fff;
    padding: 10px 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out 0s;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    position: absolute;
    bottom: 3.9rem;
    right: 3rem;
    z-index: 500;
}
.learn-more {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: #111121;
    background: #fff;
    padding: 10px 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    position: absolute;
    bottom: 3.9rem;
    left: 3rem;
    z-index: 500;
    &::before {
        display: block;
        content: "";
        height: 1px;
        width: 3rem;
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        top: 50%;
        left: -3rem;
    }
}
.flaticon-plus::before {
    content: "\f10a";
}
.workhere-title h2::before {
    position: absolute;
    left: 0;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 7s infinite linear;
    -webkit-animation: rotateline 7s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(
        to right,
        rgba(255, 120, 16, 1) 0%,
        rgba(255, 21, 106, 1) 25%,
        rgba(255, 94, 44, 1) 50%,
        rgba(187, 13, 76, 1) 75%,
        rgba(69, 204, 137, 1) 100%
    );
}

/*---------ipad landscape mode media query-----------*/
@media (max-width: 1024px) and (min-width: 768px) {
    .single_screen_slide {
        width: 460px;
        padding: 56px 17px 90px 19px !important;
        background-size: 100% !important;
        

        .owl-carousel {
            height: 79.8%;                
        }
    }
    .workhere-main{
    .mobile_app_layout {
        padding: 28px 0px 34px 148px !important;
        width: 330px;
        .owl-carousel {
            border-radius: 16px;              
        }
    }
}

}
@media all and (width: 1024px) and (height: 768px) and (orientation:landscape){
    .workhere-main {
        .testimonial-title {
            text-align: center;
        }
        .testimonial-title:before {
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
        .home_text_slide {
            p {
                text-align: center;
            }
            .owl-nav {
                left: 50%;
            }
            .owl-dots {                
                margin-top: 0 !important;
            }
        }

       
        .single_screen_slide {
            width: 460px;
            padding: 56px 17px 90px 19px;
            background-size: 100%;
            

            .owl-carousel {
                height: 79.8%;                
            }
        }

        .mobile_app_layout {
            padding: 6.2% 29% 7% 29% !important;
            .owl-carousel {
                border-radius: 16px;              
            }
        }
    }
}





/*----------Tab responsive css-------------*/
@media (max-width: 768px) {

    .table-view {
        display: none !important;
    }
    .round-animation {
        .svg_upper{
    
            position: absolute;
            z-index: 99999 !important;
            overflow: hidden;
            width: 100%;
            height: 390px;
            top: 36%;

            svg {
                left: 40.8%;
                top: 20%;
                transform: scale(1.8);
                width: 99%;

            }
        }
    }

    .workhere-main {
        .testimonial-title {
            text-align: center;
        }
        .testimonial-title:before {
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
        .home_text_slide {
            p {
                text-align: center;
            }
            .owl-nav {
                left: 50%;
            }
            .owl-dots {                
                margin-top: 0 !important;
            }
        }

        .desktop_sl {
            -webkit-box-ordinal-group: -1;
            -ms-flex-order: -1;
            order: -1;
        }

       
        .single_screen_slide {
            background-size: 100%;
            padding: 99px 14px 42px 14px !important;
            width: 340px;
            margin: auto;

            .owl-carousel {
                height: 57.8%;                
            }
        }

        .mobile_app_layout {
            padding: 3.2% 36% 4% 36% !important;
            .owl-carousel {
                border-radius: 16px;              
            }
        }
    }
}

/*---------mobile media query---------*/

@media (max-width: 768px) and (min-width: 574px) {
    
    .workhere-main {
        .single_screen_slide {
            padding: 8.7% 4% 16.4% 4%;           
        }

        .mobile_app_layout {
            padding: 5.4% 32% 5% 31%;
            
        }
        .mobile_app_layout {
            padding: 28px 60px 34px 58px !important;
            width: 300px;
            .owl-carousel {
                border-radius: 16px;              
            }
        }
    }

    .round-animation {
        .svg_upper{    
            svg {
                top: 0 !important;
            }          
        }
    }

  
    

    
    
    
}


@media (max-width: 575px) {
    .workhere-tabs {
        .react-tabs__tab-list {
            margin-left: 0px !important;
            margin-top: 0px !important;
            display: grid;
        }
        .react-tabs__tab {
            margin: 0 auto 10px !important;
            float: none;
            display: block;
        }
    }

    .round-animation {
        .svg_upper{    
            position: unset !important;            
            height: auto !important;           
        }
    }

    .workhere-main {
        .testimonial-title {
            text-align: center;
        }
        .testimonial-title:before {
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
        .home_text_slide {
            p {
                text-align: center;
            }
            .owl-nav {
                left: 50%;
            }
            .owl-dots {                
                margin-top: 0 !important;
            }
        }

        .desktop_sl {
            -webkit-box-ordinal-group: -1;
            -ms-flex-order: -1;
            order: -1;
        }
        .single_screen_slide {
            background-size: 100%;
            padding: 99px 14px 42px 14px !important;
            width: 340px;
            margin: auto;

            .owl-carousel {
                height: 57.8%;                
            }
        }

        .mobile_app_layout {
            padding: 28px 60px 34px 58px !important;
            width: 300px;
            .owl-carousel {
                border-radius: 16px;              
            }
        }
    }

    
}

@media (max-width: 360px) {
    .workhere-main .single_screen_slide {
        padding: 122px 10px 75px 8px !important;
        width: 280px;
       
    }
    .workhere-main .mobile_app_layout {
        padding: 28px 10px 24px 8px !important;
        width: 200px;
       
    }
}

