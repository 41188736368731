.chat-btn {
    display: none;
    position: fixed;
    bottom: 30px;
    z-index: 600;
    right: 20px;
    // -webkit-animation: fadeIn 8s ease-in-out 1s both;
    // animation: fadeIn 8s ease-in-out 1s both;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    transform-origin: center center;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: $primary-color;
    .dow-arrow {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
        transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
        // opacity: 0;
        transform: rotate(0deg);
        svg{
            width: 16px;
            path{
                fill: #ffffff; 
            }
        }
    }
    .chat-icon {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
        transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
        // opacity: 0;
        transform: rotate(0deg) scale(1);
        color: #ffffff;
        svg{
            width: 28px;
            height: 32px;
            path{
                fill: #ffffff; 
            }
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1 ;
}
