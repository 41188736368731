.casedetail-bg-white {
    background-color: #fff;
}

.casedetail-content {
    padding: 80px 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.casedetail-header {
    background: #f3f6ff url(/static/media/9.6333c55a.png);
    display: block;
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    text-align: center;
    -webkit-animation: slide 20s linear infinite;
}
.casedetail-img {
    width: 100%;
    height: 160px;
    border-radius: 5px;
}
.casedetail-title {
    font-size: 50px;
    color: #1c2045;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 20px;
}
.casedetail-title-pattern {
    bottom: 0;
    position: absolute;
}

.casedetail-title h6 {
    padding: 3px 5px;
    position: relative;
    display: inline-block;
    font-weight: 500;
    background: #000;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.casedetail-title h2 {
    font-size: 38px;
    font-style: normal;
    line-height: 46px;
    font-weight: 600;
    color: #1c2045;
    text-transform: capitalize;
    margin-bottom: 10px;
    position: relative;
    letter-spacing: 2px;
}

.casedetail-page-head.row {
    border-bottom: 1px solid #e7e9f2;
}

.casedetail-title-details {
    color: #111121 !important;
    line-height: 1.9;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 30px;

    ul li, ol li {
        color: #111121;
    }
    b, strong {
        font-weight: bold;
    }
    p{
        color: #111121;;
    }
    h1, h2, h3, h4, h5, h6{
        color: #111121;;
    }
    
}

.casedetail-section1-card {
    padding: 60px 30px;
    background: #fff;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out 0s;
}