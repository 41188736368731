.wedo-mainclass {
    padding: 80px 0px;
    background-color: #f3f6ff;
    position: relative;
    margin-top:0px;

}
.wedo-img {
    background-image: url(../../../img/07.png);
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
@media (max-width: 992px) {
    .wedo-img {
        left: 0;
        position: inherit;
        top: inherit;
        transform: inherit;
    }
}
.wedo-text {
    h4 {
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        background: $black-color;
        color: #fff;
        letter-spacing: 2px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    h2 {
        position: relative;
        color: #1c2045;
        padding-bottom: 20px;
        margin-bottom: 25px;
        font-size: 40px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 2px;
        span{
            color: #86BC24;
        }
    }
    p {
        color: #111121 !important;
        line-height: 1.9;
        letter-spacing: 1px;
    }
    .custom_link {
        color: #86bc23;
        /* font-weight: bold; */
        text-decoration: underline;
    }
}
@media (max-width: 1200px) {
    .wedo-text h4 {
        font-size: 17px;
        line-height: 22px;
    }
}
@media (max-width: 992px) {
    .wedo-text h4 {
        font-size: 17px;
        line-height: 26px;
    }
}
@media (max-width: 767px) {
    .wedo-text h2 {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
    .wedo-text h2 {
        font-size: 28px;
        line-height: 36px;
    }
}
.wedo-title {
    list-style: none;
    padding-left: 0px;
    li {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 15px;
        width: calc(100% / 2);
        color: #1c2045;
        letter-spacing: 1px;
    }
}
.wedo-list-icon li {
    padding-left: 20px;
    position: relative;
}
@media (max-width: 576px) {
    .wedo-title li {
        width: 100%;
    }
    .wedo-mainclass{
        padding:20px 0px !important;
    } 
    
}
.wedo-list-icon li::before {
    content: "";
    background: $black-color;
    position: absolute;
    top: 8px;
    left: 0;
    width: 10px;
    height: 2px;
    border-radius: 4px;
}
.round-animation {
    background-image: linear-gradient(120deg, #3d2513 0%, #000000 100%);
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    width: 100%;
    height: 100%;
    animation: morph 10s linear infinite;
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
    position: relative;
}
.round-animation::before {
    background-image:linear-gradient(120deg, rgba(55, 55, 56, 0.55) 0%, rgba(63, 61, 61, 0.89) 100%);
    animation: morph 3s linear infinite;
    opacity: 0.21;
    animation-duration: 1.5s;
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    will-change: border-radius, transform, opacity;
    animation-delay: 200ms;
    // background-image: linear-gradient(120deg,rgba(255,94,44,.55) 0%,rgba(255,120,16,.89) 100%);
}
.round-animation::after {
    background-image: linear-gradient(120deg, rgba(55, 55, 56, 0.55) 0%, rgba(63, 61, 61, 0.89) 100%);
    animation: morph 3s linear infinite;
    opacity: 0.21;
    animation-duration: 1.5s;
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    will-change: border-radius, transform, opacity;
    animation-delay: 200ms;
    // background-image: linear-gradient(120deg,rgba(255,94,44,.55) 0%,rgba(255,120,16,.89) 100%);
}
.round-animation {
.svg_upper{

//  svg{
//     height: 100%;
//     position: absolute;
//     left: 42.8%;
//     top: 20%;
//     z-index: 99;
//     transform: scale(1.9);
//     width: 99%;
//     overflow: hidden;
// }

svg{
    height: 100%;
    position: absolute;
    left: -2%; 
    top: 20%;
    z-index: 99;
    // transform: scale(1.9);
    width: 188%;
    overflow: hidden;
}

}

}

@media (max-width: 767px) and (min-width: 574px) {
//@media (max-width: 768px) {

    .round-animation {
        .svg_upper{
    
            position: absolute;
            z-index: 99999 !important;
            overflow: hidden;
            width: 500px;
            height: 280px;
            top: 30%;
        }
    }

    .table-view {
        display: none !important;
    }
  
}
@keyframes morph {
    0%,
    100% {
        border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        opacity: 0.89;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}
.round-animation-img {
    z-index: 99;
    position: relative;
}

.home-animation-svg{
    path{
        fill: none;
        stroke: #000000;
        stroke-width:3;
    }
}



#path4 {
		  stroke-dasharray: 29238.806640625;
		  animation: dash1 5s linear forwards; 
	}
  
	@keyframes dash{
		from{
			stroke-dashoffset:10194.4814453125;
		}
		to{
			stroke-dashoffset:0;
		}
	}
	@keyframes dash1{
		from{
			stroke-dashoffset:29238.806640625;
		}
		to{
			stroke-dashoffset:0;
		}
	}



