.engineering-page {
    padding: 80px 0px;
    background-color: white;
    color: black;
position: relative;
overflow: hidden;
    p {
        color: black;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 30px;
    }
    span {
        color: #86BC24;
        background: #fff;
        
    }
}
@media (max-width: 992px) {
    .engineering-page p {
        font-size: 14px;
        line-height: 26px;
    }
}
.section-title {
    color:#1c2045;
    font-size: 38px;
    padding-bottom: 20px;
    position: relative;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 2px;
    span {
        color: #86bc24;
    }
}
.section-title::before {
    position: absolute;
    left: 0;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    -webkit-animation: rotateline 7s infinite linear;
    content: "";
    border-radius: 5px;
    animation: rotateline 7s infinite linear;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@media (max-width: 767px) {
    .section-title {
        font-size: 34px;
        line-height: 40px;
    }
}


.service-card {
    padding: 40px 20px;
    position: relative;
    height: 100%;    
    .service-icons {
    color: $primary-color;
    font-size: 30px;             
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
    transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    display : flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 86px;
    position: relative;
    background-color: #86BC24;
    border-radius:20% 80% 30% 20% ;  
    animation: one-animated 10s infinite;
    overflow: hidden;
    transition: all 0.5s ease-out 0s;
    

        svg {
            width: 35px;
            height: 35px;
            fill: #fff;
        }
    }
    h5 {
        margin: 30px 0px 20px;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 500;
        color:#000;
        line-height: 20px;
        letter-spacing: 2px;
    }
    p {
        font-size: 14px;
        line-height: 1.9;
        letter-spacing: 1px;
        font-weight: 400;
    }
  
@keyframes one-animated {
0% { border-radius: 20% 80% 30% 20%;}
25% { border-radius: 58% 42% 75% 25%;}
50% { border-radius: 50% 50% 33% 67%;}
75% { border-radius: 33% 67% 58% 42%;}
}
@-webkit-keyframes wobble-horizontal {
16.65% { -webkit-transform: translateX(8px); transform: translateX(8px); }
33.3% { -webkit-transform: translateX(-6px); transform: translateX(-6px); }
49.95% { -webkit-transform: translateX(4px); transform: translateX(4px); }
66.6% { -webkit-transform: translateX(-2px); transform: translateX(-2px); }
83.25% { -webkit-transform: translateX(1px); transform: translateX(1px); }
100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
@keyframes wobble-horizontal {
16.65% { -webkit-transform: translateX(8px); transform: translateX(8px); }
33.3% { -webkit-transform: translateX(-6px); transform: translateX(-6px); }
49.95% { -webkit-transform: translateX(4px); transform: translateX(4px); }
66.6% { -webkit-transform: translateX(-2px); transform: translateX(-2px); }
83.25% { -webkit-transform: translateX(1px); transform: translateX(1px); }
100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
          
}
.service-card:hover .service-icons{
    -webkit-animation-name: wobble-horizontal;
    animation-name: wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}


@media (max-width: 1200px){
    .service-card h5 {
        font-size: 18px;
        line-height: 24px;
}
}
@media (max-width: 992px){
    .service-card h5 {
    font-size: 18px;
    line-height: 28px;
}
}
.service-card:hover {
    transform: inherit;
    border-radius: 15px;
    background: #fff;
    -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);

    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    .service-icons {
        background-color: $black-color;
        fill: #fff !important;

        
    }
    svg {
        fill: #fff !important;
    }
}

@media (max-width: 576px) {
    .section-title {
        font-size: 28px;
        line-height: 36px;
    }
    .engineering-page{
        padding:30px 0px;
    }
    .service-card{
        padding: 15px 0px !important;
    }
}
  

