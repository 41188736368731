.contactus-bg-white {
    background-color: #ffffff;
}
.contactus-header {
    background: #f8f8fb url(../../../img/08.webp);
    display: block;
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    text-align: center;
    
    svg {
        #followPath {
            fill: none;
            stroke: #86bc24;
            stroke-width: 8;
            stroke-dasharray: 2870px;
            stroke-dashoffset: 2870px;
            -webkit-animation: drawPath 6s linear infinite;
            animation: drawPath 6s linear infinite;
        }
         #dashedPath {
            fill: none;
            stroke: #fff;
            stroke-width: 14;
            stroke-dasharray: 50px;
        } 
        #airplain {
            fill: #86bc24;
            -webkit-transform: translate(-2px, -45px);
            transform: translate(-2px, -45px);
        }
    }
    .ani_right {
        height: 320px;
        width: 320px;
        right: 0;
        top: 0;
        position:absolute;
    }
    @-webkit-keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }
        @keyframes drawPath {
        0% { opacity: 1; stroke-dashoffset: 2870px; }
        53% { opacity: 1; stroke-dashoffset: 0; }
        78% { opacity: 0; }
        100% { opacity: 0; stroke-dashoffset: 0; }
        }

        .ani.ani-5 {
            left: 5%;
            top: 10%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            position:absolute;
       
        .moving-square-frame {
            position: absolute;
            height: 40px;
            width: 40px;
            top: 10px;
            left: 10px;
            opacity: .4;
            border: 1px solid #1d1d1f;
        }
        .ani-moving-square {
            border: 1px solid #86bc24;
            height: 40px;
            width: 40px;
            opacity:1;
            -webkit-animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
            animation: ani-moving-square 15s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        }
        @-webkit-keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
            @keyframes ani-moving-square {
            0%, 2.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            10%, 15% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            22.5%, 27.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            35%, 40% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            47.5%, 52.5% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            60%, 65% { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
            72.5%, 77.5% { -webkit-transform: translate(20px, 20px); transform: translate(20px, 20px); }
            85%, 90% { -webkit-transform: translate(20px, 0); transform: translate(20px, 0); }
            97.5%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
            }
    }


.ani_inner_banner{
    left: 100px;
    bottom: 100px;
    -webkit-transform: translate(-50%, 50%) scale(0.8);
    transform: translate(-50%, 50%) scale(0.8);
    position: absolute;

    .ani-cube {
        -webkit-perspective: 20000px;
        perspective: 20000px;
        height: 100px;
        width: 100px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        transform: rotateX(
    -30deg) rotateY(
    40deg) rotateZ(
    -360deg);
        -webkit-animation: ani-cube-1 20s linear infinite;
        animation: ani-cube-1 20s linear infinite;
    }

    .ani-cube.ani-cube-3 {
        -webkit-transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        transform: scale(0.6) rotateX(
    -63deg) rotateY(
    13deg) rotateZ(
    -47deg);
        -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
        animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  

        .cube-face.face_back {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, -50px);
            transform: translate3d(0px, 0px, -50px);
            z-index: 1;
        }
        .cube-face {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 100px;
            height: 100px;
        }  

       
       
        .cube-face.face_front {
            background-color: #86bc24;
            -webkit-transform: translate3d(0px, 0px, 50px);
            transform: translate3d(0px, 0px, 50px);
            z-index: 10;
        }
        .cube-face.face_right {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 8;
        }
        .cube-face.face_left {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        0deg) rotateY(
        -90deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 9;
        }
        .cube-face.face_top {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 11;
        }
        .cube-face.face_bottom {
            background-color: #86bc24;
            -webkit-transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            transform: rotateX(
        -90deg) rotateY(
        0deg) rotateZ(
        0deg) translate3d(0px, 0px, 50px);
            z-index: 5;
        }
        
        .cube-face.face_front {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_right {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_left {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_top {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_bottom {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }
        .cube-face.face_back {
            border: 2px solid #86bc24;
            border-radius: 2px;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #86bc24, 0 0 0 2px #86bc24;
        }             

        @-webkit-keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            @keyframes ani-cube-3 {
            0% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(-47deg); }
            10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-47deg); }
            100% { -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(-407deg); }
            }
            
    }

}        

}
@media (max-width: 992px) {
    .contactus-header {
        padding: 160px 0px;
    }
}
@media (max-width: 576px) {
    .contactus-header {
        padding: 160px 0 110px;
    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}
.contactus-title {
    font-size: 50px;
    color: #1c2045;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 20px;
}
.pagenotfound {
    padding: 80px 0px;

    h2{
        color: #111121 !important;
        font-weight: 600;
        font-size: 35px;
        text-align: center;
        padding: 30px 0px;
        margin: 0px;
    }
    .homepage_btn{
        text-align: center;
    }
}
.contactus-title::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    position: absolute;
    width: 100px;
    margin: 0 auto;
    bottom: 0;
    height: 3px;
    animation: rotateline 07s infinite linear;
    -webkit-animation: rotateline 07s infinite linear;
    content: "";
    border-radius: 5px;
    background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}

@media (max-width: 767px) {
    .contactus-title {
        font-size: 30px;
        line-height: 30px;
    }
}
@media (max-width: 576px) {
    .contactus-title {
        font-size: 24px;
    }
}

.contactus-title-pattern-img {
    vertical-align: middle;
}
.contactus-title-pattern {
    bottom: 0;
    position: absolute;
}
.contactus-content {
    padding: 80px 0px;
    text-align: center;
    border-top:1px solid #86bc24;
}
.conactus-item-left{
    h5{
        color: #86BC24;       
        margin-bottom: 25px;
        font-size:15px;
        font-weight: 900;
        font-style: normal;
        text-align:left;
        text-transform:uppercase;
        letter-spacing:20px;
        padding-left:15px;

    }
   } 
.contactus-office {
    background: #fff;
    min-height:150px;
    margin-bottom: 20px;
    text-align:left;
   
    h3 {
        position: relative;
        color: #1c2045;
        margin-bottom: 20px;
        font-size: 22px;
        font-style: normal;
        line-height: 36px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing:0px;
    }
}
// .australia-office {
//     background-image: url(../../../img/26.png);
// }
// .contactus-office h3::before {
//     left: 50%;
//     -webkit-transform: translateX(-50%);
//     position: absolute;
//     width: 100px;
//     margin: 0 auto;
//     left: 0;
//     bottom: 0;
//     height: 3px;
//     animation: rotateline 07s infinite linear;
//     -webkit-animation: rotateline 07s infinite linear;
//     content: "";
//     border-radius: 5px;
//     background: -webkit-gradient(
//         linear,
//         left top,
//         right top,
//         color-stop(0%, rgba(255, 120, 16, 1)),
//         color-stop(25%, rgba(255, 21, 106, 1)),
//         color-stop(50%, rgba(255, 94, 44, 1)),
//         color-stop(75%, rgba(187, 13, 76, 1)),
//         color-stop(100%, rgba(255, 120, 16, 1))
//     );
//     background: -webkit-linear-gradient(
//         left,
//         rgba(255, 120, 16, 1) 0%,
//         rgba(255, 21, 106, 1) 25%,
//         rgba(255, 94, 44, 1) 50%,
//         rgba(187, 13, 76, 1) 75%,
//         rgba(69, 204, 137, 1) 100%
//     );
//     background: linear-gradient(
//         to right,
//         rgba(255, 120, 16, 1) 0%,
//         rgba(255, 21, 106, 1) 25%,
//         rgba(255, 94, 44, 1) 50%,
//         rgba(187, 13, 76, 1) 75%,
//         rgba(69, 204, 137, 1) 100%
//     );
// }
.contact-section{
    padding:4rem 2rem;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: -10rem;
    background: #fff;
    min-height:600px;
    
.form-control{
    border:0px;
    border-bottom:1px solid #1c2045;
    border-radius: 0px;
    box-shadow: none;
} 
 .thanks-img{
 img{
     width:50%;
 }
 
 }  
 .back-home{
    width:auto !important;   
   }  

}



@media (max-width: 767px) {
    .contactus-office h3 {
        font-size: 22px;
        line-height: 30px;
    }
}
@media (max-width: 576px) {
    .contactus-office h3 {
        font-size: 20px;
        line-height: 28px;
    }
}

.contactus-info-list {
    padding-left: 0;
    list-style: none;
    transition: all 0.5s ease-in-out 0s;
    p {
        color: #000;
        letter-spacing: 1px;
    }
    a {
        color: #000;
        transition: all 0.5s ease-in-out 0s;
        letter-spacing: 1px;
    }
    li {
        padding-left: 0;
        position: relative;
        color: #000;
        margin-bottom:8px !important;
    }
}
.contactus-info-list a:hover {
    color: #3414bc;
    transition: all 0.5s ease-in-out 0s;
}
.map-form {
    background: url(../../../img/23.png);
    padding: 80px 0px;
}
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.row-eq-height > [class*="col-"] > div {
    height: 100%;
}
.success_msg{
    text-align: center;
    color: #86bc24;
    padding: 2px 5px;
    margin: 0 auto;
}
 .contact-section-title {

    margin-bottom: 20px;
    h6 {
        background-color: $black-color;
        padding: 3px 5px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    h2 {
        font-size: 38px;
        font-style: normal;
        line-height: 46px;
        font-weight: 600;
        color: #1c2045;
        text-transform: capitalize;
        margin-bottom: 10px;
        position: relative;
        margin-top: 0;
        letter-spacing: 2px;
        span {
            color: #86bc24;
        }
    }
}
@media (max-width: 1200px) {
     .contact-section-title h6 {
        font-size: 17px;
        line-height: 22px;
    }
}
@media (max-width: 992px) {
    .contact-section-title h6 {
        font-size: 17px;
        line-height: 26px;
    }
}
@media (max-width: 767px) {
     .contact-section-title h2 {
        font-size: 34px;
        line-height: 40px;
    }
}
@media (max-width: 576px) {
     .contact-section-title h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

.form-group {
    margin-bottom: 20px;
    position: relative;
    textarea {
        height: 150px;
        border-radius: 5px;
    }
}
.form-control {
    height: 50px;
    border-radius: 5px;
    font-size: 13px;
    color: #111121;
    background: #fff;
    border: 2px solid #e2dfdf;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    line-height: 1.5;
}
.hirus-errors{
    position: absolute;
    color: red;
    right: 15px;
    font-size: 10px;
    line-height: 14px;
}
.form-control:focus{
    border-color: $primary-color !important
}
.form-control:focus-visible{
    outline: none;
}
.form-submit-btn {
    background:#1c2045 !important;
    color: #fff !important;
    padding: 12px 25px;
    font-weight: 500;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 5px;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 9;
    width: 167px;
    font-size: 1rem;
    line-height: 1.5;
    max-height: 48px;
}
.form-submit-btn:hover {
    color: #fff !important;
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background-color: rgba(255, 255, 255, 0.2);
}
@-webkit-keyframes jello {
    0%,
    11.1%,
    100% {
        -webkit-transform: none;
        transform: none;
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}


@media (max-width: 1024px) and (min-width: 768px) {
.conactus-item-left{
padding-left:15px !important;
}  
.order-lg-12.pl-0.pr-0.col-md-12.col-lg-6{
    padding-left:15px !important;
    padding-right:15px !important;
}
.contact-section{
margin-top:0rem;
margin-bottom:2rem;
}

}


@media (max-width: 576px) {
   .contactus-header{
        .ani_inner_banner {
            left: 10px;
            bottom: 10px;
            transform: translate(-20%, 20%) scale(0.5);
        } 
    }
    .contactus-header {
        .ani.ani-5{display:none;}
    }
    .contactus-header {
        .ani_right{
            height: 100px;
            width: 100px;
        }
    }
 
    .contactus-content{
  padding:20px 0px;
 .conactus-item-left{
   padding-left:15px !important;
   order:13 !important; 
   h5{
    padding-left:15px;   
    margin-top: 10px !important;
    letter-spacing: 10px;
   }
}  
.contact-section{
    margin: 2rem 0.8rem !important; 
    margin-top:-3rem !important;   
}  
.order_xs{
   order:1 !important; 
}
}
.pagenotfound {
    padding: 15px 0px;
    
        h2{
            padding: 20px 0px;
            font-size: 25px;
        }
    
    
}


}