.small-loader {
    margin-top: -10px;
}

.loader {
    --path: #ffffff;
    --duration: 3s;
    width: 44px;
    height: 44px;
    position: relative;
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        background: $primary-color;
        top: 37px;
        left: 19px;
        transform: translate(-18px, -18px);
        animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        rect,
        polygon,
        circle {
            fill: none;
            stroke: var(--path);
            stroke-width: 10px;
            stroke-linejoin: round;
            stroke-linecap: round;
        }
        polygon {
            stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
            stroke-dashoffset: 0;
            animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
        rect {
            stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
            stroke-dashoffset: 0;
            animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
        circle {
            stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
            stroke-dashoffset: 75;
            animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
    }
    &.triangle {
        width: 48px;
        &:before {
            left: 21px;
            transform: translate(-10px, -18px);
            animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
    }
}

@keyframes pathTriangle {
    33% {
        stroke-dashoffset: 74;
    }
    66% {
        stroke-dashoffset: 147;
    }
    100% {
        stroke-dashoffset: 221;
    }
}

@keyframes dotTriangle {
    33% {
        transform: translate(0, 0);
    }
    66% {
        transform: translate(10px, -18px);
    }
    100% {
        transform: translate(-10px, -18px);
    }
}

@keyframes pathRect {
    25% {
        stroke-dashoffset: 64;
    }
    50% {
        stroke-dashoffset: 128;
    }
    75% {
        stroke-dashoffset: 192;
    }
    100% {
        stroke-dashoffset: 256;
    }
}

@keyframes dotRect {
    25% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(18px, -18px);
    }
    75% {
        transform: translate(0, -36px);
    }
    100% {
        transform: translate(-18px, -18px);
    }
}

@keyframes pathCircle {
    25% {
        stroke-dashoffset: 125;
    }
    50% {
        stroke-dashoffset: 175;
    }
    75% {
        stroke-dashoffset: 225;
    }
    100% {
        stroke-dashoffset: 275;
    }
}

.loader {
    display: inline-block;
    margin: 0 16px;
}

// large-loader

.large-loader {
    height: 250px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    /* display: flex; */
    display: inline-block;
    .box1,
    .box2,
    .box3 {
        border: 16px solid #86BC24;
        box-sizing: border-box;
        position: absolute;
        display: block;
    }
    .box1 {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
        animation: anime1 4s 0s forwards ease-in-out infinite;
    }
    .box2 {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
        animation: anime2 4s 0s forwards ease-in-out infinite;
    }
    .box3 {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
        animation: anime3 4s 0s forwards ease-in-out infinite;
    }
}

@-moz-keyframes anime1 {
    0% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    75% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 0px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
}
@-webkit-keyframes anime1 {
    0% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    75% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 0px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
}
@-o-keyframes anime1 {
    0% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    75% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 0px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
}
@keyframes anime1 {
    0% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
    75% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 0px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
}
@-moz-keyframes anime2 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    50% {
        width: 112px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
}
@-webkit-keyframes anime2 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    50% {
        width: 112px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
}
@-o-keyframes anime2 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    50% {
        width: 112px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
}
@keyframes anime2 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    25% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    50% {
        width: 112px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
}
@-moz-keyframes anime3 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    25% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 64px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    100% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
}
@-webkit-keyframes anime3 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    25% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 64px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    100% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
}
@-o-keyframes anime3 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    25% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 64px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    100% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
}
@keyframes anime3 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
    25% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 64px;
    }
    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    75% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }
    100% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
}
