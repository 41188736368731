.footer {
  background-color: #fff;
  padding: 0;
  background-image: url(../../../img/01.webp);
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  position: relative;
  background-size: contain;
  display: block;

  .nav {
    display: block;
    float: left;
    margin-bottom: 0;
    padding-top: 25px;
    list-style: none;
  }

  .nav-item {
    display: block;
  }

  .nav-link {
    color: $white;
    padding: 10px 0;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .title {
    text-align: left !important;
  }

  .copyright {
    font-size: $font-size-sm;
    line-height: 1.8;
    color: $white;
  }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}
.footer-content {
  position: relative;
  z-index: 9;
  .col-lg-4{
    padding: 0;
  }
}
.footer-intro {
  padding: 40px;
  z-index: 2;
  display: inline-block;
  background-color: #1d1d1f;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  background-size: contain;
}
@media (max-width: 1200px) {
  .footer-intro {
    padding: 30px;
  }
}
.footer-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.footer-intro-text {
  font-size: 15px;
  margin-top: 1%;
  margin-bottom: 1rem;
  line-height: 25px;
}
.footer-readmore {
  color: white !important;
  text-decoration: none !important;
  &:hover {
    color: $primary-color !important;
    transition: all 0.5s ease-in-out 0s;
  }
}
.social-link {
  display: inline-block;
  list-style: none;
  padding: 0px;
  margin: 0 8px 0 0;
  
  a {
    color: #ffffff !important;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #efefef;
  }
  a:hover {
    background-color: #5d82d1;
    color: #fff;
    border: 0;
  }
}
.footer-section-2 {
  padding-top: 80px;
  padding-bottom: 80px;
  color: #fefefe !important;
  background-color: #1d1d1f;
  h3 {
    color: #fefefe;
    position: relative;
    font-size: 24px;

    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2px;
  }
  h4 {
    color: #fefefe;
    position: relative;
    font-size: 24px;
    padding-bottom: 20px;
    margin-bottom: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2px;
  }
}
.footer-section-2 h4::before {
  position: absolute;
  left: 0;
  width: 100px;
  margin: 0 auto;
  bottom: 0;
  height: 3px;
  animation: rotateline 07s infinite linear;
  -webkit-animation: rotateline 07s infinite linear;
  content: "";
  border-radius: 5px;
  background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@media screen and (max-width: 992px) {
  .footer-section-2 {
    padding-left: 50px;
    padding-right: 50px;
    h4 {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
@media (max-width: 576px) {
  .footer-section-2 h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .footer-section-2{
    padding-left: 30px;
    padding-right: 30px; 
  } 
}
.footer-section-2 li {
  position: relative;
  z-index: 99;
  font-size: 16px;
  padding: 2px 0px;
  list-style: none;
  transition: all 0.5s ease-in-out 0s;
  margin-bottom: 15px;
  color: #ffffffcc;
  letter-spacing: 1px;
  a {
    font-size: 16px;
    color: #ffffffcc;
    transition: all 0.5s ease-in-out 0s;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 2px 0;
    text-decoration: none !important;
  }
}
.footer-section-2 {
  .footer-link li{
    
    
    a:hover  {
      color: #ffffff;
      background-color: $black-color;
      padding: 2px 10px;
    }
  }
  li a:hover {
    color: #ff7810;
  }
}
.footer-section-2 ul {
  padding: 0px;
  padding-left: 0px;
}
.footer-news {
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  position: relative;

  justify-content: space-between !important;

  subscribe-form {
    width: 100;
    input {
      width: 100%;
      border: 2px solid #e2dfdf;
      background: #fff;
      border-radius: 5px;
      color: #111121;
      font-size: 14px;
      padding: 0 20px;
      height: 50px;
    }
  }
  .footer-errors{
    position: absolute;
    color: red;
    right: 15px;
    font-size: 10px;
    line-height: 14px;
    bottom: 0;
  }
}
.subscribe-btn {
  position: absolute;
  padding: 12px 25px;
  margin-top: 0px;
  margin-left: -5px;
  top: 0;
  right: 0;
  height: 50px;
  border-radius: 0px;
  background: #1c2045;
  color: #fff;
}
@keyframes jello {
  0%,
  11.1%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.subscribe-btn:hover {
  background: #1c2045;
  color: #ffffff;
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@media (max-width: 576px) {
  .subscribe-btn {
    border-radius: 5px;
    margin-left: 0px;
    margin-bottom: 0px !important;
  }
}
.subscribe-form-style {
  margin: 0;
  padding: 0;
  position: relative;
  width: 350px;
}
@media (max-width: 767px) {
  .subscribe-form-style {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sm-mt-2 {
    margin-top: 20px !important;
  }
}

.round-p-animation {
  border-color:#86bc246b !important;
  position: absolute;
  bottom: 0;
  right: -100px;
  margin-top: -50px;
  z-index: 9;
  background: 0 0;
  width: 200px;
  height: 200px;
  border: 2px solid rgba(255, 120, 16, 0.1);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  -webkit-transition-property: color, background, border-color;
  transition-property: color, background, border-color;
}

.round-p-animation::before {
  -webkit-animation-delay: 0.5s !important;
  animation-delay: 0.5s;
}
.round-p-animation::before,
.round-p-animation:after {
  content: "";
  border:2px solid;
  border-color: inherit;
  width: 270%;
  height: 270%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: -92%;
  top: -85%;
  opacity: 1;
  -webkit-animation: 2s videomodule-anim linear infinite;
  animation: 2s videomodule-anim linear infinite;
}

@keyframes videomodule-anim {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.secondary-footer {
  padding: 30px 0px;
  background: #f3f6ff;
  color: #111121;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  @media (max-width:992px) {
    font-size: 14px;
  }
}
.copyright-annex {
  color: $primary-color;
  font-size: 15px;
  font-weight: 500;
  @media (max-width:992px) {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .footer {
    padding-left: 0px;

    .copyright {
      text-align: right;
      margin-right: 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    .copyright {
      float: right;
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer {
    .copyright {
      text-align: center;
    }

    .nav {
      float: none;
      padding-left: 0;
    }
  }
 .round-p-animation{
  width: 60px;
height: 60px;
  right: -25px;
  border:0px;
 }  
}
.footer-follow {
  position: relative;
  color: #fff;
  padding-bottom: 20px;
  margin-bottom: 25px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2px;
}
.footer-follow::before {
  position: absolute;
  left: 0;
  width: 100px;
  margin: 0 auto;
  bottom: 0;
  height: 3px;
  animation: rotateline 07s infinite linear;
  -webkit-animation: rotateline 07s infinite linear;
  content: "";
  border-radius: 5px;
  background: linear-gradient(to right, #86bc24 0%, #86bc24 25%, #245bbc 50%, #86bc24 75%, #245bbc 100%);
}
@media (max-width: 992px) {
  .footer-follow {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 576px) {
  .footer-follow {
    font-size: 18px;
    line-height: 26px;
  }
  .footer-news{
    padding:0px !important;
  }
  .footer-section-2{
    padding-top: 20px;
    padding-bottom: 20px;
  } 
}
