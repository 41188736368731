.technologes-main {
    padding: 80px 0px;
    text-align: center ;
    background-color: #f3f6ff;
    position: relative;
    overflow: hidden;
    img{
        text-align: center;
    }

    .iq-objects-03 {
        position: absolute;
        bottom:0%;
        left: -10%;
        border: 40px solid #1d1d1f12;
        border-radius: 900px;
        height: 400px;
        width: 400px;
    }

}
.technologes-bg {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
}
.technologes-type {
    background-color: #fff;
    padding: 60px 30px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: relative;
    img{
    width:80px;

    }
    h5 {
        margin: 30px 0 20px;
        text-transform: capitalize;
        font-size: 20px;
        color:$primary-color;
    }
    p{
        font-size: 16px;
        color: #1c2045;
    }
}
.topBottom{
    animation: topBottom 5s infinite;
}
@keyframes topBottom{
    0% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    
    50% {
        -webkit-transform: translate(0,20px);
        transform: translate(0,20px);
    }
    100% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
}


@media (max-width: 576px) {
    .technologes-main {
        .iq-objects-03{
            bottom: -10%;
    left: -50%;
        }  
    }
.technologes-main{
  padding:20px 0px;  
}    
.technologes-type{
    padding:15px;
}
}